import { Controller } from 'stimulus';

const DEBOUNCE_MILLISECONDS = 1000;

import flatpickr from 'flatpickr';

export default class extends Controller {
  static targets = ['form', 'searchInput', 'account', 'startDate', 'endDate'];

  connect() {
    this.searchInputTarget.select();
    this.fireSubmit = this.fireSubmit.bind(this);

    flatpickr(this.startDateTarget, {
      dateFormat: 'Y-m-d'
    });

    flatpickr(this.endDateTarget, {
      dateFormat: 'Y-m-d'
    });
  }

  /** don't submit the form unless both dates are present and are in chronological order **/
  checkDates() {
    if(this.startDateTarget.value === '') return;
    if(this.endDateTarget.value === '') return;
    if(this.startDateTarget.valueAsDate < this.endDateTarget.valueAsDate) return;

    this.submit();
  }

  /** Submit the form immediately */
  submit() {
    setTimeout(this.fireSubmit, 0);
  }

  /** Submit the form with a debounce */
  submitDebounced() {
    if (this.submitTimeout) {
      clearTimeout(this.submitTimeout);
    }

    this.submitTimeout = setTimeout(this.fireSubmit, DEBOUNCE_MILLISECONDS);
  }

  /** Fire the submit event on the form */
  fireSubmit() {
    this.formTarget.submit();
  }

}
