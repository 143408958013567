import { Controller } from 'stimulus';

export default class extends Controller {
  toggle(event){
    let notification_id = event.target.parentElement.id;

    var dropdownList = document.getElementsByClassName(`notification--${notification_id}`)[0];
    dropdownList.classList.toggle("hide");
  }
}
