import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["submitButton", "form", "errorLabel", "errorLabelPercentage"];

  connect() {
    const elements = document.getElementsByClassName('answer-element');
    this.errorLabelTarget.hidden = true;
    this.errorLabelPercentageTarget.hidden = true;

    const _this = this;
    this.submitButtonTarget.addEventListener('click', function() {
      _this.errorLabelTarget.hidden = true;
      _this.errorLabelPercentageTarget.hidden = true;

      // Uncomment this when you need validation

      for (var i = 0; i < elements.length; i++) {
        if(elements[i].type == 'checkbox') {
          if (_this.validateCheckboxes(elements) == false) {
            _this.errorLabelTarget.hidden = false;
            return;
          }
        }

        if (elements[i].value == '' || typeof elements[i].value == 'undefined'){
          _this.errorLabelTarget.hidden = false;
          return;
        }
      }

      // Check if the addition of all percentages go above 100
      // This was asked to be removed, but keep in for now

      // if (_this.percentageInputError() === true) {
      //   _this.errorLabelPercentageTarget.hidden = false;
      //   return;
      // }

      _this.formTarget.submit();
    });
  }

  validateCheckboxes(elements) {
    var count = 0;

    for (var i = 0; i < elements.length; i++) {
      if (elements[i].checked == true){
        count++;
      }
    }

    if (count > 0) {
      return true;
    }

    return false;
  }

  percentageInputError() {
    const inputs = document.querySelectorAll('.percentage input')

    if (inputs.length === 0) {
      return false;
    }

    var total = 0;
    [...inputs].forEach((el, i) => {
      var value = parseFloat(el.value)
      if (value !== NaN) {
        total += value
      }
    });

    if (total > 100) {
      return true
    }

    return false;
  }
}
