import { Controller } from 'stimulus';
import regeneratorRuntime from "regenerator-runtime";

export default class extends Controller {
  static targets = ['customerId', 'billingName', 'priceId', 'companyId']

  connect(){
    if (!document.querySelector('.payment-form form')) { return }

    const stripe_pk = document.getElementById('stripe_pk').getAttribute('stripe-pk')
    this.stripe = Stripe(stripe_pk);
    const elements = this.stripe.elements();
    this.submitButton = document.querySelector('.js-payment-form-submit');
    this.billingInformationForm = document.querySelector('.js-billing-account-form form');

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    const card = elements.create("card", { style: style, hidePostalCode: true });
    card.mount(".js-card-element");

    card.on('change', function(event) {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
        document.querySelector('.js-payment-form-submit').disabled = true
      } else {
        displayError.textContent = '';
        document.querySelector('.js-payment-form-submit').disabled = true
        if (event.complete == true){
          document.querySelector('.js-payment-form-submit').disabled = false
        }
      }
    });

    this.form = document.querySelector('.payment-form form');

    this.submitButton.addEventListener('click', ev => {
      ev.preventDefault();
      document.querySelector('.js-payment-form-submit').disabled = true

      if ((this.billingInformationForm && !this.billingInformationForm.reportValidity()) || (this.form && !this.form.reportValidity())) {
        document.querySelector('.js-payment-form-submit').disabled = false
        return
      }
      // If a previous payment was attempted, get the latest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus'
      );

      if (latestInvoicePaymentIntentStatus === 'requires_payment_method' ||
            latestInvoicePaymentIntentStatus === 'requires_action') {
        const invoiceId = localStorage.getItem('latestInvoiceId');
        const isPaymentRetry = true;
        // create new payment method & retry payment on invoice with new payment method
        this.createPaymentMethod({
          card,
          isPaymentRetry,
          invoiceId,
        });
        // this.form.submit();
      } else {
        // create new payment method & create subscription
        this.createPaymentMethod({ card });
        // this.form.submit();
      }
    });

  }

  createPaymentMethod({ card, isPaymentRetry, invoiceId }) {
    // Set up payment method for recurring usage
    let billingName = this.billingNameTarget.value
    let customerId = this.customerIdTarget.value
    let priceId = this.priceIdTarget.value


    let that = this;

    this.stripe
      .createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: billingName,
        },
      })
      .then((result) => {
        if (result.error) {
          that.innerError(result.error);
        } else {
          if (isPaymentRetry) {
            // Update the payment method and retry invoice payment
            this.retryInvoiceWithNewPaymentMethod({
              customerId: customerId,
              paymentMethodId: result.paymentMethod.id,
              invoiceId: invoiceId,
              priceId: priceId,
              that: that
            });
          } else {
            // Create the subscription
            this.createSubscription({
              customerId: customerId,
              paymentMethodId: result.paymentMethod.id,
              priceId: priceId,
            });
          }
        }
      });
  }

  createSubscription({ customerId, paymentMethodId, priceId }) {
    let that = this;

    return (
      fetch(`create_subscription`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': this.getMetaValue('csrf-token')
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result.error;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
            that: that,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(this.handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete.bind(this))
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          document.querySelector('.js-payment-form-submit').disabled = false
          this.outerMostError(error);
        })
    );
  }

  onSubscriptionComplete(result) {
    // Payment was successful.
    if (result.subscription.status === 'active') {
      // Clear localStorage in case someone wants to subscribe on another account on this computer
      // If one of the payments was a failure, meaning a failed paymentIntent exists in storage
      // and another account tries to subscribe, it will fail because of the paymentIntent linking
      // to the old customer / account
      localStorage.clear();
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      this.redirectToSubscriptionsPage(result.subscription.id);
    }
  }

  handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
    that
  }) {
    if (subscription && subscription.status === 'active') {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return that.stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            if (typeof(subscription.latest_invoice.payment_intent) != 'undefined') {
              localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
              localStorage.setItem(
                'latestInvoicePaymentIntentStatus',
                subscription.latest_invoice.payment_intent.status
              )
            }
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result.error;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer.
              // There's a risk of the customer closing the window before the callback.
              // We recommend setting up webhook endpoints later in this guide.
              return (
                fetch('refresh_subscription', {
                  method: 'put',
                  headers: {
                    'Content-type': 'application/json',
                    'X-CSRF-Token': that.getMetaValue('csrf-token')
                  },
                  body: JSON.stringify({
                    subscriptionId: subscription.id
                  })
                })
                  .then((response) => {
                    return response.json();
                  })
                  // If the card is declined, display an error to the user.
                  .then((result) => {
                    if (result.error) {
                      // The card had an error when trying to attach it to a customer.
                      throw result.error;
                    }
                    return result;
                  })
                  .then((subscription) => {
                    return {
                      priceId: priceId,
                      subscription: subscription,
                      invoice: invoice,
                      paymentMethodId: paymentMethodId,
                      that: that,
                    };
                  })
              );
            }
          }
        })
        .catch((error) => {
          document.querySelector('.js-payment-form-submit').disabled = false
          that.innerError(error);
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
    that,
  }) {

    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.

      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw { message: 'Your card was declined.' };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  retryInvoiceWithNewPaymentMethod({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
    that
  }) {
    return (
      fetch(`retry_invoice`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'X-CSRF-Token': that.getMetaValue('csrf-token')
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result.invoice,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
            that: that,
            subscription: result.subscription
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(this.handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(this.onSubscriptionComplete.bind(this))
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          document.querySelector('.js-payment-form-submit').disabled = false

          that.innerError(error);
        })
    );
  }

  redirectToSubscriptionsPage(subscription_id) {
    // Insert the source ID into the form so it gets submitted to the server

    var elementList = [
      this.createHiddenCloneInput('subscription[plan_id]', 'selected-plan-id'),
      this.createHiddenCloneInput('subscription[product_id]', 'selected-product-id'),
      this.createHiddenCloneInput('subscription[customer_id]', 'customer-id'),
      this.createHiddenInput('subscription[subscription_id]', subscription_id),
      this.createHiddenCloneInput('billing_information[full_name]', 'billing_information_full_name'),
      this.createHiddenCloneInput('billing_information[country]', 'billing_information_country'),
      this.createHiddenCloneInput('billing_information[vat_number]', 'billing_information_vat_number'),
      this.createHiddenCloneInput('billing_information[postal_address]', 'billing_information_postal_address')
    ];

    elementList.forEach((element) => {
      if(element){
        this.form.appendChild(element);
      }
    });

    this.form.submit();
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }

  innerError(error){
    const errorElement = document.getElementById('card-errors');
    errorElement.textContent = error.message;
    throw error;
  }

  outerMostError(error) {
    const errorElement = document.getElementById('card-errors');
    errorElement.textContent = error.message;
  }

  createHiddenCloneInput(name, originalElementId) {
    const originalElement = document.getElementById(originalElementId)
    if (originalElement) {
      return this.createHiddenInput(name, originalElement.value);
    }

    return null;
  }

  createHiddenInput(name, value) {
    const element = document.createElement('input');
    element.setAttribute('type', 'hidden');
    element.setAttribute('name', name);
    element.setAttribute('value', value);
    return element;
  }

}
