import { Controller } from 'stimulus';
import videojs from 'video.js';

export default class extends Controller {
  static targets = [""];

  activate() {
    document.getElementsByClassName(`bmc-active-vid-overlay`)[0].classList.remove('hide')
    videojs(document.querySelectorAll(`.bmc-active-vid .video-js`)[0]).play();
  }

  deactivate() {
    document.getElementsByClassName('bmc-active-vid-overlay')[0].classList.add('hide')
    videojs(document.querySelectorAll(`.bmc-active-vid .video-js`)[0]).pause();
  }
}
