import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import Claim from './models/claim';
import ApiRequest from './api/request';

const OVERLAY_VISIBLE_CLASS = 'claim__overlay--visible';

export default class extends Controller {
  static targets = ['companyForm']

  updateCompany() {
    // This submit needs to be debounced because of the timing on the CLICK change
    // The ENTER change is overwritten somewhere in the code and happening at the right time
    // Overwriting the click will effect too much

    const form = this.companyFormTarget
    window.setTimeout(function() { form.submit() }, 500);
  }
}
