import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
const requestHeaders = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
};

export default class extends Controller {
  static targets = ['emptyState', 'formComponent', 'form', 'reviewBtn', 'review', 'show']

  toggleGoalReviewForm(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasEmptyStateTarget) {
      this.emptyStateTarget.classList.toggle('hide');
      this.reviewBtnTarget.classList.toggle('hide');
      this.formComponentTarget.classList.toggle('hide');
    } else {
      this.formTarget.classList.toggle('hide');
      this.reviewTarget.classList.toggle('hide');
    }
  }

  addReview(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;

    this.formTarget.classList.toggle('hide');
    this.reviewTarget.innerHTML = xhr.responseText;
  }

  editReview(event) {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;

    this.showTarget.classList.toggle('hide');
    this.reviewTarget.innerHTML = xhr.responseText;
  }
}
