import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  toggleItems(event) {
    const target = event.target;
    const items = document.getElementsByClassName(`item--${target.id}`);

    for (var i = 0; i < items.length; i++) {
      items[i].classList.toggle('hide')
    }

    if (!target.id == '') {
      target.classList.toggle('rotate-expand-icon');
    }
  }
}
