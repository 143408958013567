import { Controller } from 'stimulus';

function dasherize(str) {
  return str.replace(/_/g, '-');
}

/**
 * @module Tickets.StatusController
 * @description Controller for `Tickets::StatusComponent`.
 *
 */
export default class extends Controller {
  /** @returns {Array<string>} all available statuses */
  get statuses() {
    return [].map.call(this.element.querySelectorAll('option'), option => option.value);
  }

  /** @returns {string} the currently selected status */
  get status() {
    return this.element.selectedOptions[0].value;
  }

  /** Handler for when the current status is changed */
  update() {
    this.removeCurrentStatus();
    this.addCurrentStatus();
  }

  /** Removes the current status class from the select */
  removeCurrentStatus() {
    this.statuses.forEach(status => {
      this.element.classList.remove(this.statusClass(status));
    });
  }

  /** Sets the current status class on the select */
  addCurrentStatus() {
    this.element.classList.add(this.statusClass(this.status));
  }

  /**
   * Generates a class based on the given status to be added to the select class list
   * @param {string} status - the status to generate a class for
   * @returns {string} class for the given status
   */
  statusClass(status) {
    return `ticket-status--${dasherize(status)}`;
  }
}
