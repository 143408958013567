import { Controller } from 'stimulus';
import regeneratorRuntime from "regenerator-runtime";

export default class extends Controller {
  static targets = ['customerName', 'retryFailedPayments']

  connect(){
    if (!document.querySelector('.payment-form form')) { return }

    this.tooltipIcon = document.querySelector('.tooltipped')

    const stripe_pk = document.getElementById('stripe_pk').getAttribute('stripe-pk')
    const stripe = Stripe(stripe_pk);
    const elements = stripe.elements();
    this.submitButton = document.querySelector('.js-payment-form-submit');
    this.billingInformationForm = document.querySelector('.js-billing-account-form form');

    // Custom styling can be passed to options when creating an Element.
    // (Note that this demo uses a wider set of styles than the guide below.)
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Roboto", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#9e9e9e',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    var cardElement = elements.create("card", { style: style, hidePostalCode: true, });

    cardElement.mount(".js-card-element");

    cardElement.on('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
          document.querySelector('.js-payment-form-submit').disabled = true
      } else {
        displayError.textContent = '';
        if (event.complete == true){
          document.querySelector('.js-payment-form-submit').disabled = false
        }
      }
    });

    this.form = document.querySelector('.payment-form form');

    this.submitButton.addEventListener('click', async (event) => {
      this.submitButton.disabled = true;

      event.preventDefault();
      if ((this.billingInformationForm && !this.billingInformationForm.reportValidity()) || (this.form && !this.form.reportValidity())) { return }

      var that = this;
      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: that.customerNameTarget.value,
        },
      }).then(function(result) {
        if (result.error) {
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // The payment has succeeded
          // Display a success message
          that.submitPage(result.paymentMethod.id, that.retryFailedPaymentsTarget.checked);
        }
      });
    });
  }

  submitPage(paymentMethodId, retryFailedPayments) {
    // Insert the source ID into the form so it gets submitted to the server

    var elementList = [
      this.createHiddenInput('update_card[payment_method_id]', paymentMethodId),
      this.createHiddenInput('update_card[retry_failed_payments]', retryFailedPayments),
    ];

    elementList.forEach((element) => {
      if(element){
        this.form.appendChild(element);
      }
    });

    this.form.submit();
  }

  createHiddenInput(name, value) {
    const element = document.createElement('input');
    element.setAttribute('type', 'hidden');
    element.setAttribute('name', name);
    element.setAttribute('value', value);
    return element;
  }
}
