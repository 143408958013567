import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';

/**
 * @module TurbolinksFormController
 * @description submits a form using Turbolinks instead of a POST/GET.
 *   NOTE: Form must be remote: true (or local: false)
 */
export default class extends Controller {
  /**
   * Handler for ajax:before-send event
   * @param {event} event the event from the form
   */
  turbolink(event) {
    const { detail: [, { url }] } = event;
    Turbolinks.visit(url);
    event.preventDefault();
  }
}
