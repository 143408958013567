import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['navItem', 'contentItem']

  toggle(event) {
    if (event.target.classList.contains('tabs__tabs-btn--active') == false) {
      this.navItemTargets.forEach(target => {
        if (target.classList.contains('tabs__tabs-btn--active')) {
          target.classList.remove('tabs__tabs-btn--active')
        }
        event.target.classList.add('tabs__tabs-btn--active')
      });
      this.contentItemTargets.forEach(target => {
        if (target.id.includes(event.target.id)) {
          target.classList.add('-active')
        } else {
          target.classList.remove('-active')
        }
      });

    }
  }
}
