import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'userAlterationModal'];

  connect() {
    this.flagCount = 0;
  }

  increaseFlagCount() {
    this.flagCount += 1;
  }

  checkPermissions() {
    if (this.flagCount > 0) {
      this.userAlterationModalTarget.classList.remove('hide')
    } else {
      this.submit();
    }
  }

  cancelSubmission() {
    this.userAlterationModalTarget.classList.add('hide')
  }

  submit() {
    this.formTarget.submit();
  }
}
