import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  toggleMenu(event) {
    const menu = document.getElementById(`menu-${event.target.id}`)

    menu.classList.toggle('hide')
  }
}
