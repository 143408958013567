import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["show", "path"];

  toggle(event) {
    const series = document.getElementsByClassName(`series--${event.target.id}`);
    const multiSeriesGraph = document.getElementsByClassName('multi-series-graph');

    for (var i = 0; i < series.length; i++) {
      series[i].classList.toggle('hide');
    }

    this.buildTitles();

    if (multiSeriesGraph) {
      this.baselineSwitch();
    }
  }

  toggleBaseline(event) {
    const baseline = document.getElementById(`baseline--${event.target.id}`);
    baseline.classList.toggle('hide');
  }

  baselineSwitch() {
    var visibleSerieses = [];
    for (var i = 0; i < 4; i++) {
      var seriesData = document.getElementsByClassName(`series--${i}`);

      if (!seriesData[i].classList.contains('hide')) {
        visibleSerieses.push(i);
      }
    }

    if (visibleSerieses.length === 1 && visibleSerieses[0] === 0) {
      var baseline = document.getElementsByClassName(`baseline--${visibleSerieses[0]}`);

      for (var i = 0; i < baseline.length; i++) {
        baseline[i].classList.remove('hide')
      }
    } else {
      for (var j = 0; j < 4; j++) {
        var baseline = document.getElementsByClassName(`baseline--${j}`);
        for (var k = 0; k < baseline.length; k++) {
          if (!baseline[k].classList.contains('hide')) {
            baseline[k].classList.add('hide');
          }
        }
      }
    }
  }

  buildTitles() {
    var pathData = this.pathTargets;
    var titleArray = [];
    for(var index in this.showTargets) {
      if(!pathData[index].classList.contains('hide')) {
        titleArray.push(this.showTargets[index].textContent);
      }
    }
    const titleDiv = document.getElementById('graph-title-dynamic');
    titleDiv.innerHTML = titleArray.join('; ');
  }
}
