import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  copyLink() {
    var copyText = document.getElementById("campaign-link");

    navigator.clipboard.writeText(copyText.value).then(function() {
      alert('Copying to clipboard was successful!');
    }, function(err) {
      alert("Could not copy text: " + err);
    });
  }
}
