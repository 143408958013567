import { Controller } from 'stimulus';
import Rails from '@rails/ujs';


export default class extends Controller {
  static targets = ['elementsDiv', 'deleteButton'];

  addTextField() {
    var elementNumber = this.elementsDivTarget.children.length + 1;

    // Options for the select element
    var label = document.createElement('label');
    label.innerHTML = 'Please select a Text Field type:';

    // Select element Creation
    var selectOptions = { name: `cm_question[text_field_element_${elementNumber}]` }
    var selectElement = document.createElement('select');
    Object.assign(selectElement, selectOptions);

    // Add select options
    for (var i = 0; i < this.textFieldTypeOptions().length; i++) {
      var selectOption = this.textFieldTypeOptions()[i];
      selectElement.options.add( new Option(selectOption.text, selectOption.value, selectOption.selected) );
    }

    // Wrapping all the elements in a div that can be managed by scss / styling
    var textFieldWrapper = document.createElement('div');
    textFieldWrapper.appendChild(label);
    textFieldWrapper.appendChild(selectElement);
    textFieldWrapper.classList.add('cm-questions__form__dynamic-elements__text-field-element')

    // Wrapping the above wrapper in a final div (every element goes through this process)
    this.elementsDivTarget.appendChild(this.elementWrapper(textFieldWrapper));
  }

  addBoolean() {
    var elementNumber = this.elementsDivTarget.children.length + 1;

    // Options for the radio buttons
    var options = { type: 'radio', disabled: true }

    // Yes label and radio button
    var yesLabel = document.createElement('label');
    yesLabel.innerHTML = 'Yes';
    var yesRadio = document.createElement('input');
    Object.assign(yesRadio, options);

    // Set Yes radio button to selected
    yesRadio.checked = true;

    // No label and radio button
    var noLabel = document.createElement('label');
    noLabel.innerHTML = 'No';
    var noRadio = document.createElement('input');
    Object.assign(noRadio, options);

    // This hidden is added otherwise the element isn't submitted as boolean_element (disabled radio buttons don't get submitted?)
    var hiddenOptions = { type: 'radio', name: `cm_question[boolean_element_${elementNumber}]`, hidden: true}
    var hiddenRadio = document.createElement('input');
    hiddenRadio.checked = true
    Object.assign(hiddenRadio, hiddenOptions);

    // Wrapping radio buttons to ensure they act as a single entity in styling
    var booleanWrapper = document.createElement('div');
    booleanWrapper.appendChild(yesLabel);
    booleanWrapper.appendChild(yesRadio);
    booleanWrapper.appendChild(noLabel);
    booleanWrapper.appendChild(noRadio);
    booleanWrapper.appendChild(hiddenRadio);
    booleanWrapper.classList.add('cm-questions__form__dynamic-elements__boolean-element')

    // Wrapping the above wrapper in a final div (every element goes through this process)
    this.elementsDivTarget.appendChild(this.elementWrapper(booleanWrapper));
  }

  addSelect() {
    var elementNumber = this.elementsDivTarget.children.length + 1;

    // Label for the select element
    var label = document.createElement('label');
    label.innerHTML = 'Please enter the select options here:';

    // Select element creation
    var options = { name: `cm_question[select_element_${elementNumber}]` }
    var selectElement = document.createElement('textarea');
    Object.assign(selectElement, options);

    // Wrapping label and slect to ensure they act as a single entity in styling
    var selectWrapper = document.createElement('div');
    selectWrapper.appendChild(label);
    selectWrapper.appendChild(selectElement);
    selectWrapper.classList.add('cm-questions__form__dynamic-elements__select-element')

    // Wrapping the above wrapper in a final div (every element goes through this process)
    this.elementsDivTarget.appendChild(this.elementWrapper(selectWrapper));
  }

  addDate() {
    var elementNumber = this.elementsDivTarget.children.length + 1;

    // Date element creation
    var dateElement = document.createElement('input');

    // Use setAttribute here instead of Object.assign because of data-controller not being picked up
    dateElement.setAttribute('name', `cm_question[date_element_${elementNumber}]`)
    dateElement.setAttribute('data-controller', 'flatpickr')

    // Wrapping the date element in a final div (every element goes through this process)
    this.elementsDivTarget.appendChild(this.elementWrapper(dateElement));
  }

  removeElement(event) {
    // There is some randomness linked here for some reason
    // It needs some deeper investigation before we can fix it

    var elementsDiv = document.getElementById('elements-div')

    if (event.target.parentNode.parentNode === elementsDiv){
      event.target.parentNode.remove();
    }
    else {
      event.target.parentNode.parentNode.remove();
    }
  }

  elementWrapper(element) {
    var tempDeleteButton = document.getElementsByClassName('temp_delete_button')[0]
    var deleteButton = tempDeleteButton.cloneNode(true);
    deleteButton.classList.add('cm-questions__form__dynamic-elements__remove-field')
    deleteButton.classList.remove('hide')
    deleteButton.addEventListener('click', this.removeElement);

    var wrapper = document.createElement('div');
    wrapper.classList.add('cm-questions__form__dynamic-elements__wrapper')

    wrapper.appendChild(element);
    wrapper.appendChild(deleteButton);

    return wrapper;
  }

  textFieldTypeOptions() {
    return [
      { "text" : "Text Field", "value" : "text_field", "selected" : true },
      { "text" : "Number", "value" : "number" },
      { "text" : "Percentage", "value" : "percentage" },
      { "text" : "Email", "value" : "email" },
      { "text" : "Phone", "value" : "phone" },
      { "text" : "URL", "value" : "url" },
      { "text" : "Text Area", "value" : "text_area" },
      { "text" : "Currency","value" : "currency" }
    ];
  }
}
