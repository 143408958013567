import { Controller } from 'stimulus';

export default class extends Controller {

  connect(){
    const textBoxes = document.getElementsByClassName('number-text')

    for (var i = 0; i < textBoxes.length; i++){
      this.setInputFilter(
        textBoxes[i],
        function(value) {
          return /^-?\d*[.]?\d*$/.test(value);
        }
      );
    }
  }

  tabToNext(event) {
    if(event.key === 'Tab'){
      this.formatNumber(event)
    }
  }

  formatIntegerNumber(event) {
    let value = event.target.value.replace(",", "");
    let first = value.split(".")[0];
    event.target.value = first.replace(/\D/g, "");
  }

  formatNumber(event) {
    let value = event.target.value.replace(',', '');
    let first = value.split('.')[0]
    let second = value.split('.')[1]
    event.target.value = first.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (typeof second != 'undefined') {
      event.target.value += "." + second
    }
  }

  formatPercentage(event) {
    if (parseFloat(event.target.value) > 100) {
      event.target.value = '100'
    }
  }

  setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
      textbox.addEventListener(event, function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = this.value.replaceAll(' ', '');
        }
      });
    });
  }
}
