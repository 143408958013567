import { Controller } from 'stimulus';

export default class extends Controller {

  showInfo(event) {
    const infoStepText = document.getElementById(event.target.id + '-text')
    const infoTextBox = document.getElementsByClassName('info-text')[0]

    infoTextBox.innerHTML = infoStepText.innerHTML;
    infoTextBox.classList.toggle("hide");
  }

  hideInfo() {
    const infoTextBox = document.getElementsByClassName('info-text')[0]
    infoTextBox.innerHTML = "";
    infoTextBox.classList.toggle("hide");
  }
}
