import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import ApiRequest from './api/request';

export default class extends Controller {
  static targets = ['form', 'status', 'comment', 'button']

  rejectComment(event) {
    event.preventDefault();

    let url = this.formTarget.dataset.url;
    let status = this.statusTarget.value;
    let comment = this.commentTarget.value;

    let request = new ApiRequest();
    let options = {
      url: url,
      body: {
        status: status,
        comment: comment
      }
    }

    request.updateClaimStatus(options)
      .then(resp => { window.location.href = resp.redirect_path })
      .catch(error => console.log(error));
  }
}
