import { Controller } from "stimulus";

const SELECTED_CLASS = "category-select__subcategories-list--selected";

/**
 * @module Tickets.CategorySelectController
 * @description Controller for `Tickets::CategorySelectComponent`.
 *
 */
export default class extends Controller {
  static targets = ["category", "subcategory", "subcategoriesList"];

  /** @returns {string} the id of the currently selected category */
  get categoryId() {
    return this.categoryTarget.value;
  }

  connect() {
    this.showCurrentSubcategories();
  }

  /** Handler for when category is changed */
  update() {
    this.showCurrentSubcategories();
    this.clearSubcategorySelection();
  }

  /** Show the subcategories for currently selected category */
  showCurrentSubcategories() {
    this.subcategoriesListTargets.forEach((list) => {
      if (list.dataset.categoryId === this.categoryId) {
        list.classList.add(SELECTED_CLASS);
      } else {
        list.classList.remove(SELECTED_CLASS);
      }
    });
  }

  /** Deselect any selected subcategories */
  clearSubcategorySelection() {
    this.subcategoryTargets.forEach((subcategory) => {
      subcategory.checked = false;
    });
  }

  handleClick(e) {
    this.clearSubcategorySelection();
    e.target.checked = true;
  }
}
