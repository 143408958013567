import {
  initialXValue, horizontalDistanceBetweenDataPoints, yValue, highestYValue,
  indicatorNameAbbr, unitValue, anyNegativeYvalues
} from './graph_utilities'

function addToolTipContainer(dataPoints, toolTipContainer, indices) {
  toolTipContainer.setAttribute('stroke', '#63676B');
  toolTipContainer.setAttribute('stroke-width', '0.3');
  toolTipContainer.setAttribute('fill', '#f0f0f0');
  toolTipContainer.setAttribute(
    'class',
    `hide bar-graph__info-graphic--left multi-series-value--${indices['index']}${indices['period']}${indices['point_index']}`
  );

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipContainer.setAttribute(
      'd',
      `m${initialXValue(dataPoints) + 4 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}, ${value <= 0 ? 25 : 28-(20*value/highestYValue(dataPoints))} q0,-5 5,-5 h10 q5,0 5,5 v3.5 q0,5 -5,5 h-15 z`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipContainer.setAttribute(
      'd',
      `m${203 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}, ${value <= 0 ? 25 : 28-(20*value/highestYValue(dataPoints))} q0,-5 -5,-5 h-10 q-5,0 -5,5 v3.5 q0,5 5,5 h15 z`
    );

  } else if (highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipContainer.setAttribute(
      'd',
      `m${initialXValue(dataPoints) + 2 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}, ${value <= 0 ? 25 : 28-(20*value/highestYValue(dataPoints))} q0,-5 -5,-5 h-10 q-5,0 -5,5 v3.5 q0,5 5,5 h15 z`
    );
  } else if (highestYValue(dataPoints) == 0 && anyNegativeYvalues(dataPoints)) {
    toolTipContainer.setAttribute(
      'd',
      `m${initialXValue(dataPoints) + 2 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}, 5 q0,-5 -5,-5 h-10 q-5,0 -5,5 v3.5 q0,5 5,5 h15 z`
    );
  }

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipContainer.setAttribute(
      'd',
      `m${initialXValue(dataPoints) + 4 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}, ${49-(40*value/highestYValue(dataPoints))} q0,-5 5,-5 h10 q5,0 5,5 v3.5 q0,5 -5,5 h-15 z`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipContainer.setAttribute(
      'd',
      `m${203 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}, ${49-(40*value/highestYValue(dataPoints))} q0,-5 -5,-5 h-10 q-5,0 -5,5 v3.5 q0,5 5,5 h15 z`
    );

  } else if (highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipContainer.setAttribute(
      'd',
      `m${initialXValue(dataPoints) + 2 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}, ${49-(40*value/highestYValue(dataPoints))} q0,-5 -5,-5 h-10 q-5,0 -5,5 v3.5 q0,5 5,5 h15 z`
    );

  } else if (highestYValue(dataPoints) == 0 && !anyNegativeYvalues(dataPoints)) {
    toolTipContainer.setAttribute(
      'd',
      `m${initialXValue(dataPoints) + 2 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}, 49 q0,-5 -5,-5 h-10 q-5,0 -5,5 v3.5 q0,5 5,5 h15 z`
    );
  }

  return toolTipContainer;
}

function addToolTipIndicatorAbbr(dataPoints, indicatorAbbr, indicatorName, indices) {
  indicatorAbbr.setAttribute('line-height', '8');
  indicatorAbbr.setAttribute('font-size', '2.7');
  indicatorAbbr.setAttribute('font-weight', '400');
  indicatorAbbr.innerHTML = indicatorNameAbbr(indicatorName);
  indicatorAbbr.setAttribute(
    'class',
    `hide multi-series-value--${indices['index']}${indices['period']}${indices['point_index']}`
  );

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    indicatorAbbr.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 6 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      `${value <= 0 ? 24 : 26-(20*value/highestYValue(dataPoints))}`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    indicatorAbbr.setAttribute(
      'x',
      `${187 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      `${value <= 0 ? 24 : 26-(20*value/highestYValue(dataPoints))}`
    );

  } else if (highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    indicatorAbbr.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      `${value <= 0 ? 24 : 26-(20*value/highestYValue(dataPoints))}`
    );
  } else if (highestYValue(dataPoints) == 0 && anyNegativeYvalues(dataPoints)) {
    indicatorAbbr.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      '6'
    );
  }

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    indicatorAbbr.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 6 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      `${47-(40*value/highestYValue(dataPoints))}`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    indicatorAbbr.setAttribute(
      'x',
      `${187 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      `${47-(40*value/highestYValue(dataPoints))}`
    );

  } else if (highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    indicatorAbbr.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      `${47-(40*value/highestYValue(dataPoints))}`
    );
  } else if (highestYValue(dataPoints) == 0 && !anyNegativeYvalues(dataPoints)) {
    indicatorAbbr.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    indicatorAbbr.setAttribute(
      'y',
      '47'
    );
  }

  return indicatorAbbr;
}

function addToolTipDate(dataPoints, date, indices) {
  date.setAttribute('line-height', '8');
  date.setAttribute('font-size', '2.7');
  date.setAttribute('font-weight', '400');
  date.innerHTML = dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][0];
  date.setAttribute(
    'class',
    `hide multi-series-value--${indices['index']}${indices['period']}${indices['point_index']}`
  );

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    date.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 6 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}`
    );
    date.setAttribute(
      'y',
      `${value <= 0 ? 27 : 29-(20*value/highestYValue(dataPoints))}`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    date.setAttribute(
      'x',
      `${187 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}`
    );
    date.setAttribute(
      'y',
      `${value <= 0 ? 27 : 29-(20*value/highestYValue(dataPoints))}`
    );

  } else if(highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    date.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    date.setAttribute(
      'y',
      `${value <= 0 ? 27 : 29-(20*value/highestYValue(dataPoints))}`
    );
  } else if (highestYValue(dataPoints) == 0 && anyNegativeYvalues(dataPoints)) {
    date.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    date.setAttribute(
      'y',
      '9'
    );
  }

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    date.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 6 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}`
    );
    date.setAttribute(
      'y',
      `${50-(40*value/highestYValue(dataPoints))}`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    date.setAttribute(
      'x',
      `${187 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}`
    );
    date.setAttribute(
      'y',
      `${50-(40*value/highestYValue(dataPoints))}`
    );

  } else if(highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    date.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    date.setAttribute(
      'y',
      `${50-(40*value/highestYValue(dataPoints))}`
    );
  } else if (highestYValue(dataPoints) == 0 && !anyNegativeYvalues(dataPoints)) {
    date.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    date.setAttribute(
      'y',
      '50'
    );
  }

  return date;
}

function addToolTipValue(dataPoints, toolTipValue, indices) {
  toolTipValue.setAttribute('line-height', '8');
  toolTipValue.setAttribute('font-size', '2.7');
  toolTipValue.setAttribute('font-weight', '400');
  toolTipValue.innerHTML = unitValue(
    indices['currency'],
    indices['unit'],
    dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0
  );
  toolTipValue.setAttribute(
    'class',
    `hide multi-series-value--${indices['index']}${indices['period']}${indices['point_index']}`
  );

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipValue.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 6 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}`
    );
    toolTipValue.setAttribute(
      'y',
      `${value <= 0 ? 30 : 32-(20*value/highestYValue(dataPoints))}`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipValue.setAttribute(
      'x',
      `${187 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}`
    );
    toolTipValue.setAttribute(
      'y',
      `${value <= 0 ? 30 : 32-(20*value/highestYValue(dataPoints))}`
    );

  } else if(highestYValue(dataPoints) != 0 && anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipValue.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    toolTipValue.setAttribute(
      'y',
      `${value <= 0 ? 30 : 32-(20*value/highestYValue(dataPoints))}`
    );
  } else if (highestYValue(dataPoints) == 0 && anyNegativeYvalues(dataPoints)) {
    toolTipValue.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    toolTipValue.setAttribute(
      'y',
      '12'
    );
  }

  if (indices['dataSetsInFirstPeriod'] + indices['point_index'] < 6 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipValue.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 6 + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index'] + indices['dataSetsInFirstPeriod']))}`
    );
    toolTipValue.setAttribute(
      'y',
      `${53-(40*value/highestYValue(dataPoints))}`
    );

  } else if(indices['dataSetsInFirstPeriod'] + indices['point_index'] > 5 && indices['period'] == 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipValue.setAttribute(
      'x',
      `${187 - (1.75*(4 - indices['index'])) - (horizontalDistanceBetweenDataPoints()*(11 - indices['point_index']))}`
    );
    toolTipValue.setAttribute(
      'y',
      `${53-(40*value/highestYValue(dataPoints))}`
    );

  } else if(highestYValue(dataPoints) != 0 && !anyNegativeYvalues(dataPoints)) {
    var value = yValue(dataPoints[indices['index']]['data'][indices['period']][1][indices['point_index']][1] || 0);
    toolTipValue.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    toolTipValue.setAttribute(
      'y',
      `${53-(40*value/highestYValue(dataPoints))}`
    );
  } else if (highestYValue(dataPoints) == 0 && !anyNegativeYvalues(dataPoints)) {
    toolTipValue.setAttribute(
      'x',
      `${initialXValue(dataPoints) - 16 + ((indices['dataSetsInFirstPeriod']+1.24)*7) + (1.75*indices['index']) + (horizontalDistanceBetweenDataPoints()*(indices['point_index']))}`
    );
    toolTipValue.setAttribute(
      'y',
      '53'
    );
  }

  return toolTipValue;
}

export {
  addToolTipContainer, addToolTipIndicatorAbbr, addToolTipDate, addToolTipValue
}
