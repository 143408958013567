import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'password'];

  connect(){
    /**
     * Hack: This will allow us to mark flatpicker datepickers as required fields
     * {@see @link https://github.com/flatpickr/flatpickr/issues/892 }
     */
    $("#datetimepicker").prop('readonly', false)
  }

  validateStartDate() {
    this.validateDate(this.endDateTarget, this.startDateTarget, 'biggerThan')
  }

  validateEndDate() {
    this.validateDate(this.startDateTarget, this.endDateTarget, 'smallerThan')
  }

  validateDate(fixedDateInput, changingDateInput, logic) {
    if (!fixedDateInput.value) {
      return;
    }

    const fixedDate = new Date(fixedDateInput.value);
    const changingDate = new Date(changingDateInput.value);

    var check;

    if (logic === 'biggerThan') {
      check = changingDate > fixedDate
    } else if (logic === 'smallerThan') {
      check = changingDate < fixedDate
    }

    if (check){
      changingDateInput.value = fixedDateInput.value
      changingDateInput.nextSibling.value = this.formatDate(fixedDate)
    }
  }

  formatDate(date) {
    return date.toLocaleString('en-US', {
      day: 'numeric', // numeric, 2-digit
      year: 'numeric', // numeric, 2-digit
      month: 'long', // numeric, 2-digit, long, short, narrow
    });
  }

  copyPassword() {
    navigator.clipboard.writeText(this.passwordTarget.value).then(function() {
      alert('Copying to clipboard was successful!');
    }, function(err) {
      alert("Could not copy text: " + err);
    });
  }
}
