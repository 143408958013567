function isDateRangeAbove36Months(fromDate, toDate, graphNoLegend) {
  var numberOfMonthsBetweenDates;

  if (toDate > fromDate && graphNoLegend.length > 0 ) {
    toDate = new Date(toDate);
    fromDate = new Date(fromDate);
    numberOfMonthsBetweenDates = toDate.getMonth() - fromDate.getMonth() + (12 * (toDate.getFullYear() - fromDate.getFullYear()));
    return numberOfMonthsBetweenDates > 36 ? true : false;
  } else {
    return false;
  }
}

export { isDateRangeAbove36Months }
