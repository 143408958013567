import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import Cropper from 'cropperjs';


export default class extends Controller {
  static targets = ['result', 'imgResult', 'imgW', 'imgH', 'save' , 'cropped', 'hiddenInput', 'modalBox', 'fileInput'];

  connect() {
    this.cropper = '';
  }

  deactivate() {
    this.fileInputTarget.value = '';
    this.croppedTarget.classList.add('hide');
    this.imgResultTarget.classList.add('hide');
    this.saveTarget.classList.add('hide');
    this.modalBoxTarget.classList.add('hide');
  }

  clear() {
    this.fileInputTarget.value = '';
  }

  // on change show image with crop options
  upload(e) {
    if (e.target.files.length) {
      const reader = new FileReader();

      reader.onload = (e)=> {
        if(e.target.result){

          // create new image
          let img = document.createElement('img');
          img.id = 'image';
          img.src = e.target.result

          // clean result before
          this.resultTarget.innerHTML = '';

          // append new image
          this.resultTarget.appendChild(img);

          // show save btn and options
          this.modalBoxTarget.classList.remove('hide');

          // init cropper
          this.cropper = new Cropper(
            img,
            this.cropperOptions()
          );
        }

      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  cropperOptions() {
    return {
      aspectRatio               : parseInt(this.imgWTarget.value)/parseInt(this.imgHTarget.value),
      cropBoxResizable          : false,
      cropBoxMovable            : false,
      dragMode                  : 'move',
      toggleDragModeOnDblclick  : false,
    }
  }

  updateCrop() {
    // get result to data uri
    let imgSrc = this.cropper.getCroppedCanvas({
      width: this.imgWTarget.value, // input value
      height: this.imgHTarget.value, // input value
    }).toDataURL();

    // show preview image (could be hidden after deactivate was run)
    this.imgResultTarget.classList.remove('hide');
    this.croppedTarget.classList.remove('hide');

    this.croppedTarget.src = imgSrc;
    this.croppedTarget.width = this.imgWTarget.value;
    this.croppedTarget.height = this.imgHTarget.value;
    this.hiddenInputTarget.value = imgSrc;

    this.saveTarget.classList.remove('hide');
  }

  // save on click
  saveCrop(e) {
    e.preventDefault();

    // get result to data uri
    let imgSrc = this.cropper.getCroppedCanvas({
      width: this.imgWTarget.value, // input value
      height: this.imgHTarget.value, // input value
    }).toDataURL();

    // remove hide class of img
    this.croppedTarget.classList.remove('hide');
    this.imgResultTarget.classList.remove('hide');
    this.saveTarget.classList.add('hide');
    this.modalBoxTarget.classList.add('hide');

    // show image cropped
    this.croppedTarget.src = imgSrc;
    this.hiddenInputTarget.value = imgSrc;

    // Checks if theres an image outside of the component to update on save
    let outsideImage = document.getElementById(`${this.hiddenInputTarget.id}_outside`)

    if (outsideImage) {
      outsideImage.src = imgSrc
    }
  }
}
