import { Controller } from 'stimulus';

/**
 * @module DropdownController
 * @description Shows and hides a simple dropdown.
 *
 */
export default class extends Controller {
  static targets = ['content']

  connect() {
    this.element.addEventListener('focusout', this.hide.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('focusout', this.hide.bind(this));
  }

  get visible() {
    return !this.contentTarget.classList.contains('hide');
  }

  toggle(e) {
    e.preventDefault();

    if (this.visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  hide() {
    //Hack: delay the hiding of the dropdown to allow the click event to be processed in the dropdown  
    setTimeout(() => {
      this.contentTarget.classList.add('hide');
    }, 150);
  }

  show() {
    this.contentTarget.classList.remove('hide');
  }
}
