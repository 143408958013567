import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  /** Fire the submit event on the form */
  fireSubmit(event) {
    const indicator_id = event.target.id;
    const theForm = document.getElementById(`form-${indicator_id}`)

    Rails.fire(theForm, 'submit');
  }

  toggleEdit(event) {
    setTimeout(function(){
      let indicator_id = event.target.id;
      let name = event.target.name;
      var indicatorAction = document.getElementsByClassName(`indicator-update--${indicator_id}`);
      var editIndicatorBtn = document.getElementsByClassName(`edit-indicator--btn--${indicator_id}`)[0];
      var editIndicatorIcon = document.getElementsByClassName(`edit-indicator--icon--${indicator_id}`)[0];
      var cancelIndicatorUpdateIcon = document.getElementsByClassName(`cancel-indicator-update--icon--${indicator_id}`)[0];
      var cancelIndicatorUpdateBtn = document.getElementsByClassName(`cancel-indicator-update--btn--${indicator_id}`)[0];
      var updateIndicator = document.getElementsByClassName(`update-indicator--${indicator_id}`)[0];
      var updateIndicatorBtn = document.getElementsByClassName(`update-indicator--btn--${indicator_id}`)[0];

      //Action Buttons
      editIndicatorBtn.classList.toggle("hide");
      editIndicatorIcon.classList.toggle("hide");
      cancelIndicatorUpdateBtn.classList.toggle("hide");
      cancelIndicatorUpdateIcon.classList.toggle("hide");
      updateIndicator.classList.toggle("hide");
      updateIndicatorBtn.classList.toggle("hide");

      //Input fields
      /*
        Monthly input fields for each indicator
      */
      indicatorAction[0].getAttribute('disabled') ? indicatorAction[0].removeAttribute('disabled') : indicatorAction[0].setAttribute('disabled','disabled');
      indicatorAction[1].getAttribute('disabled') ? indicatorAction[1].removeAttribute('disabled') : indicatorAction[1].setAttribute('disabled','disabled');
      indicatorAction[2].getAttribute('disabled') ? indicatorAction[2].removeAttribute('disabled') : indicatorAction[2].setAttribute('disabled','disabled')
    }
, 1);
  }
}
