import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fullNameInput', 'countryInput', 'vatNumberInput', 'postalAddressInput', 'saveButton'];

  edit() {
    this.saveButtonTarget.classList.toggle('hide');
    this.fullNameInputTarget.disabled = false;
    this.countryInputTarget.disabled = false;
    this.vatNumberInputTarget.disabled = false;
    this.postalAddressInputTarget.disabled = false;
  }

}
