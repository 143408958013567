import { Controller } from "stimulus"
import Highcharts from 'highcharts';
import HC_more from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
HC_more(Highcharts);

export default class extends Controller {
    static targets = ['holder', 'dataField'];

  connect() {
    let dataArray = this.dataFieldTarget.value.split(",");
    dataArray.forEach(function(item, index, arr){
      arr[index] = parseInt(item);
    });

    Highcharts.chart(this.holderTarget, {
      chart: {
        polar: true
      },
      title: {
        text: ''
      },
      pane: {
        startAngle: 0,
        endAngle: 360
      },
      plotOptions: {
         series: {
            pointPlacement: 'on'
         }
      },
      xAxis: {
        categories: ['Independence', 'Passion', 'Goal-Oriented','Conscientiousness', 'Social-Skills', 'Leadership',
            'Mentoring/Supporting', 'Self-Confidence', 'Optimism', 'Status-Seeking', 'Interest in Knowledge', 'Innovation',
            'Adaptability', 'Risk-Taking'],
        tickAmount: 14,
        tickmarkPlacement: 'on',
        angle: 0,
        startOnTick: true,
        lineColor: '#63656A'
      },
      yAxis: {
        min: 0,
        max: 100,
        minorTickInterval: 10,
        minorTicks: true,
        minorTickColor: '#63656A'
      },
      series: [{
        type: 'area',
        name: 'Entrepreneur',
        fillColor: 'rgba(236, 116, 4, 0.2)',
        color: '#D54C07',
        data: dataArray
      }, {
        type: 'line',
        name: 'Average',
        color: '#003B5C',
        data: [69, 81, 75, 81, 77, 78, 66, 83, 73, 47, 80, 70, 76, 53],
      }]
    });
  }
}
