import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { strokeWidth, numberOfDataPoints, xLabelFontSize,
          xLabelRotation, yLabelTransformOrigin
        } from './bar_graph__style_attributes';
import { serialize, separateThousands } from '../utilities';
import { drawMultiSeriesGraph } from './multi_series_graph';
import { isDateRangeAbove36Months } from './date_selector_utilities';
import { unitValue, getHighestValue } from './graph_utilities';

const requestHeaders = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
};

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const months_abbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default class extends Controller {
  static targets = ['form', 'dateRange', 'companyId', 'indicatorId', 'dateSelector', 'fromDate', 'toDate', 'id', 'period'];

  submit() {
    var fromDate, toDate, endDate;
    const graphNoLegend = document.getElementsByClassName('bar-graph__no-legend');

    if ((this.isCustomDataSelected() && this.fromDateTarget.value == '') || (this.isCustomDataSelected() && this.toDateTarget.value == '' && this.periodTarget.value == '')) {
      return;
    }

    if (this.dateRangeTarget.value != 'custom') {
      fromDate = this.dateRangeTarget.value.substring(16,26);
      toDate = this.dateRangeTarget.value.substring(41,51);
      endDate = toDate;
    } else {
      fromDate = this.fromDateTarget.value;

      endDate = new Date(fromDate);
      endDate = new Date((endDate.setMonth(endDate.getMonth() + 11))).toISOString().substring(0, 10);
      toDate = this.periodTarget.value == '' ? this.toDateTarget.value : endDate;
      if (isDateRangeAbove36Months(fromDate, toDate, graphNoLegend)) {alert('Date range should be less or equal to 36 months');}
      if (isDateRangeAbove36Months(fromDate, toDate, graphNoLegend)) {return;}
    }

    if (this.periodTarget.value != '') {
      var month, year;
      month = toDate.substring(5,7); year = toDate.substring(0,4);
      this.toDateTarget.nextSibling.value = months_abbr[month-1] + '-' + year;
      this.toDateTarget.nextSibling.disabled = true;

      drawMultiSeriesGraph(fromDate, endDate, this.companyIdTarget.value, this.indicatorIdTarget.value);
      return;
    }

    if (fromDate > toDate) {
      alert('"From Date" should be before to "To Date"');
      return;
    }

    this.fireSubmit(fromDate, toDate).then(data => {
      var dataPoints = [];
      var baseline;
      dataPoints = data[1]['data']; // data points to be plotted
      baseline = data[0]['data'][0][1];

      const indicatorId = this.idTarget.id;
      const graphWithLegend = document.getElementsByClassName('bar-graph__with-legend');
      const newSvgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const yOriginSvgPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');

      newSvgElement.setAttribute('data-controller', 'dashboard-restyle--submit-form');
      // Remove current plotted graph from DOM
      this.removeCurrentGraph(indicatorId);

      this.newSvgGraph(indicatorId, newSvgElement);
      this.drawYOrigin(newSvgElement, yOriginSvgPathElement, dataPoints);
      this.addYvalueLines(newSvgElement, dataPoints);
      this.addXmarks(newSvgElement, dataPoints);
      this.addXlabels(newSvgElement, dataPoints);
      this.plotYbars(newSvgElement, dataPoints, baseline);
      this.addYlabels(newSvgElement, dataPoints, baseline);
      this.addXYpoint(newSvgElement, dataPoints);
      this.addBaseline(newSvgElement, baseline, dataPoints, indicatorId);

      // Add the new graph to the DOM
      this.addNewGraph(indicatorId, newSvgElement);
    });
  }

  addBaseline(newSvgElement, baseline, dataPoints, indicatorId) {
    var baselinePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    var baselinePoint = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    var baselineValue = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    var baselineLegendText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    var baselineLegend = document.createElementNS('http://www.w3.org/2000/svg', 'circle');

    const currency = document.getElementsByName('currency')[0].content;
    const unit = document.getElementsByName('indicator-graph')[0].getAttribute('unit');
    var value = Math.round(baseline);

    baselinePoint.setAttribute('r', '0.5');
    baselinePoint.setAttribute('fill', '#EC7405');
    baselinePoint.setAttribute('stroke', '#EC7405');
    baselinePoint.setAttribute('cursor', 'pointer');
    baselinePoint.setAttribute('id', `${indicatorId}`);
    baselinePoint.setAttribute('data-action', 'mouseover->dashboard-restyle--data-series#toggleBaseline mouseout->dashboard-restyle--data-series#toggleBaseline');
    baselinePoint.setAttribute('cx', `${this.initialXValue(dataPoints) + 10}`);

    baselinePath.setAttribute('stroke', '#EC7405');
    baselinePath.setAttribute('stroke-width', '0.4');

    baselineLegend.setAttribute('stroke', '#EC7405');
    baselineLegend.setAttribute('fill', '#EC7405');
    baselineLegend.setAttribute('cx', '2');
    baselineLegend.setAttribute('cy', '80');
    baselineLegend.setAttribute('r','0.5');

    baselineLegendText.setAttribute('color', '#63666A');
    baselineLegendText.setAttribute('font-size', '3');
    baselineLegendText.setAttribute('font-weight', '400');
    baselineLegendText.setAttribute('x', '5');
    baselineLegendText.setAttribute('y', '81');
    baselineLegendText.innerHTML = 'Baseline';


    baselineValue.setAttribute('text-anchor', 'start');
    baselineValue.setAttribute('y', '14.5');
    baselineValue.setAttribute('font-size', '3');
    baselineValue.setAttribute('line-height', '10');
    baselineValue.setAttribute('color', '#63666A');
    baselineValue.setAttribute('font-weight', '400');
    baselineValue.setAttribute('x', `${this.initialXValue(dataPoints) + 10}`);
    baselineValue.setAttribute('id', `baseline--${indicatorId}`);
    baselineValue.innerHTML = unitValue(currency, unit, value);

    if (this.highestYValue(dataPoints) < baseline && !this.anyNegativeYvalues(dataPoints) && this.highestYValue(dataPoints) != 0) {
      baselinePoint.setAttribute('cy', '15');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 15 h100`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineValue);
      newSvgElement.appendChild(baselineLegendText);
    } else if (this.highestYValue(dataPoints) < baseline && !this.anyNegativeYvalues(dataPoints) && this.highestYValue(dataPoints) == 0) {
      baselinePoint.setAttribute('cy', '25');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 25 h100`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineLegendText);
    } else if (this.highestYValue(dataPoints) > baseline && this.highestYValue(dataPoints) != 0 && !this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', `${baseline > 0 ? 65-(40*baseline/this.highestYValue(dataPoints)) : 65}`);
      baselineValue.setAttribute('class', 'hide');
      baselineValue.setAttribute('y', `${baseline > 0 ? 63-(40*baseline/this.highestYValue(dataPoints)) : 63}`);
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} ${baseline > 0 ? 65-(40*baseline/this.highestYValue(dataPoints)) : 65} h100`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineLegendText);
      newSvgElement.appendChild(baselineValue);
    } else if (this.highestYValue(dataPoints) == baseline && this.highestYValue(dataPoints) != 0 && !this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', '25');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 25 h100`);
      newSvgElement.appendChild(baselineLegendText);
    } else if (!this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', '25');
      baselineValue.setAttribute('class', 'hide');
      baselineValue.setAttribute('y', '23');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 25 h100`);
      newSvgElement.appendChild(baselineValue);
    }

    if (this.highestYValue(dataPoints) < baseline && this.highestYValue(dataPoints) == 0 && this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', '15');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 15 h100`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineValue);
      newSvgElement.appendChild(baselineLegendText);
    } else if (this.highestYValue(dataPoints) > baseline && this.highestYValue(dataPoints) == 0 && this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', `${-1*baseline < this.highestNegValue(dataPoints, baseline) ? 65+(40*baseline/this.highestNegValue(dataPoints, baseline)) : 65}`);
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} ${-1*baseline < this.highestNegValue(dataPoints, baseline) ? 65+(40*baseline/this.highestNegValue(dataPoints, baseline)) : 65} h100`);
      baselineValue.setAttribute('class', 'hide');
      baselineValue.setAttribute('y', `${-1*baseline < this.highestNegValue(dataPoints, baseline) ? 63+(40*baseline/this.highestNegValue(dataPoints, baseline)) : 63}`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineValue);
      newSvgElement.appendChild(baselineLegendText);
    } else if (this.highestYValue(dataPoints) > baseline && this.highestYValue(dataPoints) != 0 && this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', `${baseline > 0 ? 45 - (20*baseline/this.highestYValue(dataPoints)) : 65 + (20*baseline/(-1*baseline > this.highestYValue(dataPoints) ? baseline : this.highestYValue(dataPoints)))}`);
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} ${baseline > 0 ? 45 - (20*baseline/this.highestYValue(dataPoints)) : 65 + (20*baseline/(-1*baseline > this.highestYValue(dataPoints) ? baseline : this.highestYValue(dataPoints))) } h100`);
      baselineValue.setAttribute('class', 'hide');
      baselineValue.setAttribute('y', `${baseline > 0 ? 43 - (20*baseline/this.highestYValue(dataPoints)) : 63 + (20*baseline/(-1*baseline > this.highestYValue(dataPoints) ? baseline : this.highestYValue(dataPoints)))}`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineValue);
      newSvgElement.appendChild(baselineLegendText);
    } else if (this.highestYValue(dataPoints) == baseline && this.highestYValue(dataPoints) != 0 && this.anyNegativeYvalues(dataPoints)) {
      baselinePoint.setAttribute('cy', '25');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 25 h100`);
      newSvgElement.appendChild(baselineLegendText);
    } else if (this.anyNegativeYvalues(dataPoints) && baseline > 0 && this.highestYValue(dataPoints) == 0)  {
      baselinePoint.setAttribute('cy', '25');
      baselinePath.setAttribute('d', `M${this.initialXValue(dataPoints) + 10} 25 h100`);
      newSvgElement.appendChild(baselineLegend);
      newSvgElement.appendChild(baselineValue);
      newSvgElement.appendChild(baselineLegendText);
    }

    newSvgElement.appendChild(baselinePath);
    newSvgElement.appendChild(baselinePoint);
  }

  // this will be imported from the graph_utilities file
  drawYOrigin(newSvgElement, yOriginSvgPathElement, dataPoints) {
    yOriginSvgPathElement.setAttribute(
      'd',
      `M${this.initialXValue(dataPoints) + 10} 65 h100`
    );
    yOriginSvgPathElement.setAttribute('stroke', '#E0E0E1');
    yOriginSvgPathElement.setAttribute('stroke-width', '0.1');

    newSvgElement.appendChild(yOriginSvgPathElement); //Add 0 y-axis line to the svg
  }

  // this will be imported from the graph_utilities file
  addNewGraph(indicatorId, newSvgElement) {
    var plotArea = document.getElementById('graph--'+indicatorId);
    plotArea.appendChild(newSvgElement);
  }

  removeCurrentGraph(indicatorId) {
    const svgElement = document.getElementById('svg--'+indicatorId);
    svgElement.remove();
  }

  newSvgGraph(indicatorId, newSvgElement) {
    newSvgElement.setAttribute('id', `svg--${indicatorId}`);
    newSvgElement.setAttribute('viewBox', '0 10 120 80');
  }

  // this will be imported from the graph_utilities file
  addYvalueLines(newSvgElement, dataPoints) {
    for (var i = 0; i < 4; i++) {
      var yPositionPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      yPositionPath.setAttribute('stroke', '#E0E0E1');
      yPositionPath.setAttribute('stroke-width', '0.2');
      yPositionPath.setAttribute(
        'd',
        `M${this.initialXValue(dataPoints) + 10} ${55 - (10*i)} h100`
      );
      //Add horizontal y-value line to the svg
      newSvgElement.appendChild(yPositionPath);
    }
  }

  // this will be imported from the graph_utilities file
  addXmarks(newSvgElement, dataPoints) {
    for (var i = 0; i < dataPoints.length; i++) {
      var xPositionPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

      xPositionPath.setAttribute(
        'd',
        `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 65 v2`
      );
      xPositionPath.setAttribute('stroke', '#E0E0E1');
      xPositionPath.setAttribute('stroke-width', '0.2');

      newSvgElement.appendChild(xPositionPath);
    }
  }

  addXlabels(newSvgElement, dataPoints) {
    for (var i = 0; i < dataPoints.length; i++) {
      var xLabelText = document.createElementNS('http://www.w3.org/2000/svg', 'text');

      xLabelText.innerHTML = dataPoints[i][0];
      xLabelText.setAttribute('y', `${dataPoints.length > 7 ? 77 : 72}`);
      xLabelText.setAttribute('line-height', '9');
      xLabelText.setAttribute('font-size', `${xLabelFontSize(dataPoints)}`);
      xLabelText.setAttribute('font-weight', '400');
      xLabelText.setAttribute('transform', `rotate(${xLabelRotation(dataPoints)})`);
      xLabelText.setAttribute(
        'transform-origin',
        `${this.initialXValue(dataPoints) + 8 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} ${yLabelTransformOrigin(dataPoints)}`
      );
      xLabelText.setAttribute(
        'x',
        `${this.initialXValue(dataPoints) + 8 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i) - (dataPoints.length > 27 ? 4 : 0)}`
      );

      newSvgElement.appendChild(xLabelText);
    }
  }

  plotYbars(newSvgElement, dataPoints, baseline) {
    for (var i = 0; i < dataPoints.length; i++) {
      var yBarsPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

      yBarsPath.setAttribute('class', 'bar-graph__data');
      yBarsPath.setAttribute('stroke', '#003B5C');
      yBarsPath.setAttribute('cursor', 'pointer');
      yBarsPath.setAttribute('stroke-width', `${strokeWidth(dataPoints)}`);
      yBarsPath.setAttribute('id', `${i}`);
      yBarsPath.setAttribute('data-action','mouseover->dashboard-restyle--submit-form#displayData mouseout->dashboard-restyle--submit-form#hideData');

      if (!this.isDataAvailable(dataPoints).length) {
        var waterMark = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        waterMark.setAttribute('color', '#E0E0E157');
        waterMark.setAttribute('font-size', '20');
        waterMark.setAttribute('font-weight', '400');
        waterMark.setAttribute('x', '20');
        waterMark.setAttribute('y', '52');
        waterMark.innerHTML = 'No data';

        newSvgElement.appendChild(waterMark);
      }

      if (this.highestYValue(dataPoints) == 0 && !this.anyNegativeYvalues(dataPoints) && baseline >= 0) {
        yBarsPath.setAttribute(
          'd',
          `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 65 v${-40*this.dataPoint(dataPoints, i)/1}`
        );
      } else if (!this.anyNegativeYvalues(dataPoints) && baseline >= 0)  {
        yBarsPath.setAttribute(
          'd',
          `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 65 v${-40*this.dataPoint(dataPoints, i)/this.highestYValue(dataPoints)}`
        );
      }

      if (this.highestYValue(dataPoints) == 0 && !this.anyNegativeYvalues(dataPoints) && baseline < 0) {
        yBarsPath.setAttribute(
          'd',
          `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 45 v${-20*this.dataPoint(dataPoints, i)/1}`
        );
      } else if (!this.anyNegativeYvalues(dataPoints) && baseline < 0)  {
        yBarsPath.setAttribute(
          'd',
          `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 45 v${-20*this.dataPoint(dataPoints, i)/this.highestYValue(dataPoints)}`
        );
      }

      if (this.highestYValue(dataPoints) == 0 && this.anyNegativeYvalues(dataPoints)) {
        yBarsPath.setAttribute(
          'd',
          `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 25 v${this.dataPoint(dataPoints, i) < 0 ? -(40*this.dataPoint(dataPoints, i)/this.highestNegValue(dataPoints, baseline)) : 0}`
        );
      } else if (this.anyNegativeYvalues(dataPoints))  {
        yBarsPath.setAttribute(
          'd',
          `M${this.initialXValue(dataPoints) + 15 + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i)} 45 v${this.dataPoint(dataPoints, i) < 0 ? -(20*this.dataPoint(dataPoints, i)/this.highestNegValue(dataPoints, baseline)) : -20*this.dataPoint(dataPoints, i)/this.highestYValue(dataPoints)}`
        );
      }
      newSvgElement.appendChild(yBarsPath);
    }
  }
  // this will be imported from the graph_utilities file
  addYlabels(newSvgElement, dataPoints, baseline) {
    for (var i = 0; i < 5; i++) {
      var yLabelText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      yLabelText.setAttribute('font-size', '3');
      yLabelText.setAttribute('line-height', '10');
      yLabelText.setAttribute('color', '#63666A');
      yLabelText.setAttribute('font-weight', '400');
      yLabelText.setAttribute('text-anchor', 'start');
      yLabelText.setAttribute('x', '0');
      yLabelText.setAttribute('y', `${65.5 - (10*i)}`);
      if (this.highestYValue(dataPoints) != 0 || this.anyNegativeYvalues(dataPoints) || baseline != 0) {
        yLabelText.innerHTML = this.yLabel(dataPoints, i, baseline);
      }
      newSvgElement.appendChild(yLabelText);
    }
  }

  addXYpoint(newSvgElement, dataPoints) {
    for (var i = 0; i < dataPoints.length; i++) {
      var yValue = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      const currency = document.getElementsByName('currency')[0].content;
      const unit = document.getElementsByName('indicator-graph')[0].getAttribute('unit');

      if (this.highestYValue(dataPoints) != 0) {
        yValue.setAttribute('x', `${this.initialXValue(dataPoints) + (this.horizontalDistanceBetweenDataPoints(dataPoints)*i) + 2}`);
        yValue.setAttribute('y', '22');
        yValue.setAttribute('font-size', '3');
        yValue.setAttribute('font-weight', '400');
        yValue.setAttribute('color', '#63666A');
        yValue.setAttribute('id', `value--${i}`);
        yValue.setAttribute('class', 'hide');
        yValue.innerHTML = `${unitValue(currency, unit, dataPoints[i][1])}`;
      }
      newSvgElement.appendChild(yValue);
    }
  }

  displayData(event) {
    const data = document.getElementById(`value--${event.target.id}`)
    const multiSeriesData = document.getElementsByClassName(`multi-series-value--${event.target.id}`);

    if(data){ data.classList.remove('hide'); }
    if(multiSeriesData){
      for (var i = 0; i < multiSeriesData.length; i++) {
        multiSeriesData[i].classList.remove('hide');
      }
    }
  }

  hideData(event) {
    const data = document.getElementById(`value--${event.target.id}`);
    const multiSeriesData = document.getElementsByClassName(`multi-series-value--${event.target.id}`);

    if(data){ data.classList.add('hide'); }
    if(multiSeriesData){
      for (var i = 0; i < multiSeriesData.length; i++) {
        multiSeriesData[i].classList.add('hide');
      }
    }
  }

  dataPoint(dataPoints, index) {
    return dataPoints[index][1];
  }

  allYvalues(dataPoints) {
    var yValues = [];
    for (var i = 0; i < numberOfDataPoints(dataPoints); i++) {
      yValues.push(this.dataPoint(dataPoints, i))
    }
    return yValues;
  }

  highestYValue(dataPoints) {
    var maxYvalue = Math.max(...this.allYvalues(dataPoints));
    return maxYvalue < 0 ? 0 : Math.ceil(maxYvalue/10)*10;
  }

  highestNegValue(dataPoints, baseline) {
    const values = this.allYvalues(dataPoints).map(getHighestValue);
    var highestValue = Math.max(...values);

    highestValue = highestValue >= (-1*baseline) ? highestValue : (-1*baseline);
    return Math.ceil(highestValue/10)*10;
  }

  anyNegativeYvalues(dataPoints) {
    return this.allYvalues(dataPoints).some( x => x < 0 );
  }

  initialXValue(dataPoints) {
    return this.highestYValue(dataPoints).toString().length + 2
  }
  // this will be imported from the graph_utilities file
  yLabel(dataPoints, index, baseline) {
    const currency = document.getElementsByName('currency')[0].content;
    const unit = document.getElementsByName('indicator-graph')[0].getAttribute('unit');
    var value;

    if (this.anyNegativeYvalues(dataPoints) && this.highestYValue(dataPoints) != 0) {
      value = index > 1 ? this.highestYValue(dataPoints)*0.5*(index-2) : this.highestNegValue(dataPoints, baseline)*0.5*(index-2);
    } else if (this.anyNegativeYvalues(dataPoints) && this.highestYValue(dataPoints) == 0) {
      value = -1*this.highestNegValue(dataPoints, baseline)*0.25*(4-index);
    } else if (!this.anyNegativeYvalues(dataPoints) && baseline < 0) {
      value = index < 3 ? baseline*0.5*(2-index) : this.highestYValue(dataPoints)*0.5*(index-2);
    } else if (!this.anyNegativeYvalues(dataPoints) && baseline > 0 && this.highestYValue(dataPoints) == 0) {
      value = baseline*0.25*index
    }
    else {
      value = this.highestYValue(dataPoints)*0.25*index;
    }

    value = Math.ceil(value/10)*10;

    return unitValue(currency, unit, value);
  }

  horizontalDistanceBetweenDataPoints(dataPoints) {
    return 95.0 / numberOfDataPoints(dataPoints)
  }

  isCustomDataSelected() {
    if (this.dateRangeTarget.value == 'custom') {
      this.dateSelectorTarget.classList.remove('hide');
      return true;
    } else if (!this.dateSelectorTarget.classList.contains('hide')) {
      this.dateSelectorTarget.classList.add('hide');
      return false;
    }
  }

  fireSubmit(fromDate, toDate) {
    const fromYear = new Date(fromDate).getFullYear();
    const fromMonth = months[new Date(fromDate).getMonth()];
    const toYear = new Date(toDate).getFullYear();
    const toMonth = months[new Date(toDate).getMonth()];

    const charts_params = {
                            'charts':
                            {
                              'company_id': this.companyIdTarget.value,
                              'indicator_id': this.indicatorIdTarget.value,
                              'date_range': { 'from_month': fromMonth, 'from_year': fromYear, 'to_month': toMonth, 'to_year': toYear },
                            }
                          };

    return fetch(
      `/charts/indicator_values?${serialize(charts_params)}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: requestHeaders,
      },
    ).then(response => response.json());
  }

  isDataAvailable(dataPoints) {
    var allYvalues = this.allYvalues(dataPoints);

    for (var i = 0; i < allYvalues.length; i++) {
      if (allYvalues[i] == null) {
        allYvalues.splice(i,1);
      }
    }

    return allYvalues;
  }
}
