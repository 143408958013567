import { Controller } from 'stimulus';

export default class extends Controller {

  toggleCard() {
    var dataCaptureCard = document.getElementsByClassName('data-capture__wrapper')[0];
    var dataCaptureBtn = document.getElementsByClassName('indicator-cluster__data-capture-action')[0];

    if (dataCaptureCard) {
      dataCaptureCard.classList.toggle("hide");
    }

    if (dataCaptureBtn) {
      dataCaptureBtn.classList.toggle("flex-display");
      dataCaptureBtn.classList.toggle('hide');
    }
  }
}
