import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'submitBtn', 'errorMsg'];

  validate(event) {
    let passwordField = event.target;
    
    if (passwordField.value.length > 5) {
      passwordField.classList.contains('bottom-border') ? passwordField.classList.remove('bottom-border--red') : '';
      passwordField.classList.contains('bottom-border--red') ? passwordField.classList.remove('bottom-border--red') : '';
      passwordField.classList.contains('bottom-border--green') ? '' : passwordField.classList.add('bottom-border--green');

      this.checkPasswordMatch();
    } else {
      passwordField.classList.contains('bottom-border') ? passwordField.classList.remove('bottom-border') : '';
      passwordField.classList.contains('bottom-border--green') ? passwordField.classList.remove('bottom-border--green') : '';
      passwordField.classList.contains('bottom-border--red') ? '' : passwordField.classList.add('bottom-border--red');

      this.checkPasswordMatch();
    }
  }

  checkPasswordMatch() {
    let password = this.passwordTarget.value;
    let passwordConfirmation = this.passwordConfirmationTarget.value;

    if (password.length > 5 && passwordConfirmation.length > 5 && password === passwordConfirmation) {
      this.submitBtnTarget.classList.contains('disabled') ? this.submitBtnTarget.classList.remove('disabled') : '';
      this.errorMsgTarget.classList.contains('hide') ? '': this.errorMsgTarget.classList.add('hide');
    } else {
      this.errorMsgTarget.classList.contains('hide') ? this.errorMsgTarget.classList.remove('hide') : '';
      this.submitBtnTarget.classList.contains('disabled') ? '' : this.submitBtnTarget.classList.add('disabled');
    }
  }
}
