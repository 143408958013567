import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['userAdminRole','companySelectInput', 'roleLabel', 'roleType']

  connect() {
    this.adminRole();
  }

  adminRole() {
    this.toggleCompanySelectInput()

    this.toggleRoleType();
  }

  toggleRoleType() {
    var roleLabelClasses = this.roleLabelTarget.classList;
    var roleTypeClasses = this.roleTypeTarget.classList;

    if (this.userAdminRoleTarget.value == 'company_user') {
      roleLabelClasses.contains('hide') ? roleLabelClasses.remove('hide') : '';
      roleTypeClasses.contains('hide') ? roleTypeClasses.remove('hide') : '';
    } else {
      roleLabelClasses.contains('hide') ? '' : roleLabelClasses.add('hide');
      roleTypeClasses.contains('hide') ? '' : roleTypeClasses.add('hide');
    }
  }

  toggleCompanySelectInput() {
    var companySelectInputClasses = this.companySelectInputTarget.classList;

    if (this.userAdminRoleTarget.value == 'company_user') {
      companySelectInputClasses.contains('hide') ? companySelectInputClasses.remove('hide') : '';
    }else {
      companySelectInputClasses.contains('hide') ? '' : companySelectInputClasses.add('hide');
    }
  }
}
