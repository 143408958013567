import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['close', 'open']

  toggle() {
    this.closeTarget.classList.toggle('hide');
    this.openTarget.classList.toggle('hide');

    var outlineIcons = document.getElementsByClassName('outline-icon');
    var solidIcons = document.getElementsByClassName('solid-icon');
    var allActivities = document.getElementsByClassName('activity');

    if (this.openTarget.classList.contains('hide')) {
      this.displayAllActivities(outlineIcons, solidIcons, allActivities);
    } else {
      this.hideAllActivities(outlineIcons, solidIcons, allActivities);
    }
  }

  toggleActivity(event) {
    var className = event.target.parentElement.id;
    var elements = document.getElementsByClassName('item-'+className);
    var icons = document.getElementsByClassName('icon-' + className);

    for (var i = 0; i < icons.length; i++) {
      icons[i].classList.toggle('hide');
    }

    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.toggle('hide');
    }
  }

  displayAllActivities(outlineIcons, solidIcons, allActivities) {
    for (var i = 0; i < outlineIcons.length; i++) {
      this.addActivedisplayIcon(outlineIcons[i], solidIcons[i]);
    }

    for (var i = 0; i < allActivities.length; i++) {
      this.displayActivity(allActivities[i]);
    }
  }

  addActivedisplayIcon(outlineIcon, solidIcon) {
    if (!outlineIcon.classList.contains('hide')) outlineIcon.classList.add('hide');
    if (solidIcon.classList.contains('hide')) solidIcon.classList.remove('hide');
  }

  displayActivity(activity) {
    if (activity.classList.contains('hide')) activity.classList.remove('hide');
  }

  hideAllActivities(outlineIcons, solidIcons, allActivities) {
    for (var i = 0; i < outlineIcons.length; i++) {
      this.removeActivedisplayIcon(outlineIcons[i], solidIcons[i])
    }

    for (var i = 0; i < allActivities.length; i++) {
      this.hideActivity(allActivities[i]);
    }
  }

  removeActivedisplayIcon(outlineIcon, solidIcon) {
    if (outlineIcon.classList.contains('hide')) outlineIcon.classList.remove('hide');
    if (!solidIcon.classList.contains('hide')) solidIcon.classList.add('hide');
  }

  hideActivity(activity) {
    if (!activity.classList.contains('hide')) activity.classList.add('hide');
  }

  displayDescription() {
    var className = 'activity--'+event.target.parentElement.id;
    this.toggleVisibility(className, 'visible');
  }

  hideDescription() {
    var className = 'activity--'+event.target.parentElement.id;
    this.toggleVisibility(className, 'hidden');
  }

  toggleVisibility(className, attribute) {
    var description = document.getElementsByClassName(className);
    if (description[0] !== undefined) description[0].style.visibility = attribute;
  }
}
