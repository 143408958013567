const colors = ['#003B5C', '#99B1BE', '#C1C2C3', '#63666A','#003B5C'];
const tickPathTopRow = ', 61.7c0.0038,-0.070746,-0.0075,-0.1414935,-0.0332,\
-0.2075166c-0.0125,-0.019125,-0.02984,-0.034575,-0.05026,-0.044786c-0.02041,\
-0.010211,-0.04318,-0.014797,-0.06597,-0.0132989998c-0.09464,0.00863,-0.1850899,\
0.0430809998,-0.2614768,0.0996039998c-0.123904,0.087091,-0.2392459,0.1857432,\
-0.3444817,0.2946584l-1.544,1.527c-0.095,0.092,-0.174,0.162,-0.241,0.216c-0.037,\
0.038,-0.085,0.065,-0.137,0.079c-0.058,0,-0.103,-0.046,-0.137,-0.133c-0.046,\
-0.154,-0.076,-0.313,-0.091,-0.473v-0.062c-0.033,-0.295,-0.129,-0.44,-0.29,\
-0.44c-0.0871238,0.00224,-0.172878,0.022154,-0.2520705,0.05854c-0.0791926,\
0.036386,-0.1501715,0.088481,-0.2086165,0.1531307c-0.128,0.121,-0.203,0.288,\
-0.208,0.465c-0.002,0.285,0.013,0.571,0.046,0.855c0.015364,0.1745257,0.048746,\
0.3469844,0.099608,0.5146372c0.0718179,0.1107059,0.1662836,0.204931,0.277168,\
0.2764703c0.1108845,0.071539,0.2356726,0.1187659,0.3661383,0.138563c0.181,\
-0.047,0.346,-0.141,0.477,-0.274c0.292,-0.243,0.57,-0.504,0.83,-0.781l1.5024249,\
-1.58127281l0.029048,-0.024902c0.153583,-0.1457763,0.245638,-0.3447391,0.2573,\
-0.556157v-0.0788568z';
const tickPathBottomRow = ', 67c0.0038,-0.070746,-0.0075,-0.1414935,-0.0332,\
-0.2075166c-0.0125,-0.019125,-0.02984,-0.034575,-0.05026,-0.044786c-0.02041,\
-0.010211,-0.04318,-0.014797,-0.06597,-0.0132989998c-0.09464,0.00863,-0.1850899,\
0.0430809998,-0.2614768,0.0996039998c-0.123904,0.087091,-0.2392459,0.1857432,\
-0.3444817,0.2946584l-1.544,1.527c-0.095,0.092,-0.174,0.162,-0.241,0.216c-0.037,\
0.038,-0.085,0.065,-0.137,0.079c-0.058,0,-0.103,-0.046,-0.137,-0.133c-0.046,\
-0.154,-0.076,-0.313,-0.091,-0.473v-0.062c-0.033,-0.295,-0.129,-0.44,-0.29,\
-0.44c-0.0871238,0.00224,-0.172878,0.022154,-0.2520705,0.05854c-0.0791926,\
0.036386,-0.1501715,0.088481,-0.2086165,0.1531307c-0.128,0.121,-0.203,0.288,\
-0.208,0.465c-0.002,0.285,0.013,0.571,0.046,0.855c0.015364,0.1745257,0.048746,\
0.3469844,0.099608,0.5146372c0.0718179,0.1107059,0.1662836,0.204931,0.277168,\
0.2764703c0.1108845,0.071539,0.2356726,0.1187659,0.3661383,0.138563c0.181,-0.047,\
0.346,-0.141,0.477,-0.274c0.292,-0.243,0.57,-0.504,0.83,-0.781l1.5024249,\
-1.58127281l0.029048,-0.024902c0.153583,-0.1457763,0.245638,-0.3447391,0.2573,\
-0.556157v-0.0788568z" fill="#63666A" stroke-width="0.415034';
const checkBoxPathTopRow = ',62.5h-0.05v2.54h3.55v-2.69h-3.55v0.15h0.05v0.14h3.2v2.1h-3.05v-2.24h-0.05v0.14v-0.04z';
const checkBoxPathBottomRow = ',67.7h-0.05v2.54h3.55v-2.69h-3.55v0.15h0.05v0.14h3.2v2.1h-3.05v-2.24h-0.05v0.14v-0.04z';

import { serialize, separateThousands } from '../utilities';
import {
  addToolTipContainer, addToolTipIndicatorAbbr, addToolTipDate, addToolTipValue
} from './multi_series_tooltip_utilities';

function drawYOrigin(newSvgElement, yOriginSvgPathElement, dataPoints) {
  yOriginSvgPathElement.setAttribute(
    'd',
    `M${initialXValue(dataPoints)} 55 h190`
  );
  yOriginSvgPathElement.setAttribute('stroke', '#E0E0E1');
  yOriginSvgPathElement.setAttribute('stroke-width', '0.1');

  newSvgElement.appendChild(yOriginSvgPathElement); //Add 0 y-axis line to the svg
}

function initialXValue(dataPoints) {
  const highestPos = highestYValue(dataPoints).toString().length;
  const highestNeg = highestNegValue(dataPoints).toString().length;
  return highestPos > highestNeg ?  highestPos + 12 : highestNeg + 12;
}

function highestNegValue(dataPoints) {
  const values = allYvalues(dataPoints).map(getHighestValue);
  const highestValue = Math.max(...values);
  return Math.floor(highestValue);
}

function getHighestValue(num) {
  return num < 0 ? -1*num : 1;
}

function anyNegativeYvalues(dataPoints) {
  var baselineValues = dataPoints.map(getBaselineValues).filter(Number);
  var turnoverBaseline = baselineValues[0]; //Turnover baseline

  return allYvalues(dataPoints).some(val => val < 0) || turnoverBaseline < 0;
}

function highestYValue(dataPoints) {
  const highestValue = Math.max(...allYvalues(dataPoints));
  return Math.ceil(highestValue);
}

function allYvalues(dataPoints) {
  var yValues = [];

  for (var i = 0; i < dataPoints.length; i++) {
    for (var j = 0; j < dataPoints[i]['data'].length; j++) {
      yValues.push(dataPoints[i]['data'][j][1].map(extractYValues));
    }
  }

  return yValues.flat(1);
}

function extractYValues(dataSet) {
  return dataSet[1] != null ? dataSet[1] : 0;
}

function isDataAvailable(dataPoints) {
  var yValues = [];

  for (var i = 0; i < dataPoints.length; i++) {
    for (var j = 0; j < dataPoints[i]['data'].length; j++) {
      for (var k = 0; k < dataPoints[i]['data'][j][1].length; k++) {
        if (dataPoints[i]['data'][j][1][k][1] != null) {
          yValues.push(dataPoints[i]['data'][j][1][k][1]);
        }
      }
    }
  }

  return yValues.flat(1);
}

function addNewGraph(indicatorId, newSvgElement) {
  var plotArea = document.getElementById('graph--'+indicatorId);
  plotArea.appendChild(newSvgElement);
}

function addYvalueLines(newSvgElement, dataPoints) {
  for (var i = 0; i < 4; i++) {
    var yPositionPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    yPositionPath.setAttribute('stroke', '#E0E0E1');
    yPositionPath.setAttribute('stroke-width', '0.2');
    yPositionPath.setAttribute(
      'd',
      `M${initialXValue(dataPoints)} ${45 - (10*i)} h190`
    );
    //Add horizontal y-value line to the svg
    newSvgElement.appendChild(yPositionPath);
  }
}
// newSvgElement - SVG element
// dataPoints - data points
// period - Year in which data is to be plotted (either 0 or 1)
//** previousPeriodDataSets - number of data sets in the previous period or year
// plus one the period exists and plus zero if it doesn't **
function addXmarks(newSvgElement, dataPoints, period, previousPeriodDataSets) {
  for (var j = 0; j < dataPoints[0]['data'][period][1].length; j++) {
    var xPositionPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    xPositionPath.setAttribute(
      'd',
      `M${initialXValue(dataPoints) + 7 + (horizontalDistanceBetweenDataPoints()*(j + previousPeriodDataSets))} 55 v2`
    );
    xPositionPath.setAttribute('stroke', '#E0E0E1');
    xPositionPath.setAttribute('stroke-width', '0.2');

    newSvgElement.appendChild(xPositionPath);
  }
}

function addXlabels(newSvgElement, dataPoints, period, previousPeriodDataSets) {
  for (var j = 0; j < dataPoints[0]['data'][period][1].length; j++) {
    var xLabelText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    if (j == 12 - previousPeriodDataSets) { break; }

    xLabelText.innerHTML = dataPoints[0]['data'][period][1][j][0];
    xLabelText.setAttribute('y', '60');
    xLabelText.setAttribute('line-height', '9');
    xLabelText.setAttribute('font-size', '2.7');
    xLabelText.setAttribute('font-weight', '400');
    xLabelText.setAttribute(
      'x',
      `${initialXValue(dataPoints) + 3.5 + (horizontalDistanceBetweenDataPoints()*(j + previousPeriodDataSets))}`
    );
    newSvgElement.appendChild(xLabelText);
  }
}

function addYlabels(newSvgElement, dataPoints) {
  for (var i = 0; i < 5; i++) {
    var yLabelText = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    yLabelText.setAttribute('font-size', '3');
    yLabelText.setAttribute('line-height', '10');
    yLabelText.setAttribute('color', '#63666A');
    yLabelText.setAttribute('font-weight', '400');
    yLabelText.setAttribute('text-anchor', 'start');
    yLabelText.setAttribute('x', '0');
    yLabelText.setAttribute('y', `${55.5 - (10*i)}`);
    yLabelText.innerHTML = yLabel(dataPoints, i);
    newSvgElement.appendChild(yLabelText);
  }
}

function yLabel(dataPoints, index) {
  const currency = document.getElementsByName('currency')[0].content;
  const unit = dataPoints[0]['indicator']['unit'];
  const absBottomValue = Math.abs(bottomValue(dataPoints));
  const absHighVal = topValue(dataPoints) > absBottomValue ? topValue(dataPoints) : absBottomValue;

  var value;

  if (anyNegativeYvalues(dataPoints)) {
    value = absHighVal*0.5*(index - 2);
  } else {
    value = topValue(dataPoints)*0.25*(index);
  }

  return unitValue(currency, unit, value);
}

function topValue(dataPoints) {
  var baselineValues = dataPoints.map(getBaselineValues).filter(Number);
  var highestBaseline = Math.max(...baselineValues);
  var value;

  if (highestYValue(dataPoints) > 0 && baselineValues.length > 0) {
    value = highestYValue(dataPoints) > highestBaseline ? highestYValue(dataPoints) : Math.ceil(highestBaseline);
    value = value % 4 == 0 ? value : valueDivisibleByFour(value)
  } else if (highestYValue(dataPoints) > 0) {
    value = highestYValue(dataPoints)
    value = value % 4 == 0 ? value : valueDivisibleByFour(value);
  } else if (baselineValues.length > 0 && highestBaseline > 0) {
    value = Math.ceil(highestBaseline);
    value = value % 4 == 0 ? value : valueDivisibleByFour(value);
  } else {
    value = 100
  }

  return value;
}

function bottomValue(dataPoints) {
  var baselineValues = dataPoints.map(getBaselineValues).filter(Number);
  var turnoverBaseline = baselineValues[0]; //consider only turnoverBaseline
  var negValue = highestNegValue(dataPoints)*(-1);
  var value;

  if (anyNegativeYvalues(dataPoints) && baselineValues.length > 0 && turnoverBaseline < 0) {
    value = negValue > turnoverBaseline ? Math.ceil(turnoverBaseline) : Math.ceil(negValue);
    value = value % 4 == 0 ? value : valueDivisibleByFour(value)
  } else if (anyNegativeYvalues(dataPoints)) {
    value = Math.ceil(negValue);
    value = value % 4 == 0 ? value : valueDivisibleByFour(value);
  } else if (baselineValues.length > 0 && turnoverBaseline < 0) {
    value = Math.ceil(turnoverBaseline)
    value = value % 4 == 0 ? value : valueDivisibleByFour(value);
  } else {
    value = -100
  }

  return value;
}

function valueDivisibleByFour(value) {
  while (value % 4 != 0) {
    value = value > 0 ? value + 1 : value - 1;
  }

  return value;
}

function unitValue(currency, unit, value) {
  value = value.toFixed(0);
  if (unit == 'currency_value') {
    value = value >= 0 ? currency + separateThousands(value) : '-' + currency + separateThousands(-1*value);
  } else if (unit == 'percentage') {
    value =  separateThousands(value) + '%';
  }
  return value;
}

function addLegend(newSvgElement, dataPoints, index) {
  var tick = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  var checkBox = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  var legend = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  var legendText = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  tick.setAttribute('class',`series--${index}`);
  tick.setAttribute('fill','#63666A');
  tick.setAttribute('stroke-width','0.415034');

  legend.setAttribute('id', `${index}`);
  legend.setAttribute('class', 'bar-graph__legend');
  legend.setAttribute('stroke', `${colors[index]}`);
  legend.setAttribute('stroke-width', '2.5');
  legend.setAttribute('stroke-linecap', 'round');
  legend.setAttribute('cursor', 'pointer');
  legend.setAttribute('data-action', 'click->dashboard-restyle--data-series#toggle');

  checkBox.setAttribute('fill','#63666A');

  legendText.setAttribute('y', '65');
  legendText.setAttribute('line-height', '8');
  legendText.setAttribute('font-size', '3');
  legendText.setAttribute('font-weight', '400');
  legendText.innerHTML = dataPoints[index]['indicator']['name'];

  tick.setAttribute('d', `m${6+(36.5*index)} ${tickPathTopRow}`);
  checkBox.setAttribute('d', `m${1+(36.5*index)} ${checkBoxPathTopRow}`);
  legend.setAttribute('d',`M${7+(36.5*index)} 64 h4`);
  legendText.setAttribute('x', `${14+(36.5*index)}`);

  newSvgElement.appendChild(tick);
  newSvgElement.appendChild(checkBox);
  newSvgElement.appendChild(legend);
  newSvgElement.appendChild(legendText);
}

function yValue(data) {
  return data == null ? 0 : data;
}

function horizontalDistanceBetweenDataPoints() {
  return 190.0/12.0;
}

function addToolTip(newSvgElement, dataPoints, index, point_index, period) {
  var toolTipContainer = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  var indicatorAbbr = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  var toolTipDate = document.createElementNS('http://www.w3.org/2000/svg', 'text');
  var toolTipValue = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  var dataSetsInFirstPeriod = dataPoints[index]['data'][0][1].length; //data set in the first period or year ()
  var indicatorName = dataPoints[index]['indicator']['name'];
  var unit = dataPoints[index]['indicator']['unit'];
  const currency = document.getElementsByName('currency')[0].content;
  dataSetsInFirstPeriod = period == 0 ? 0 : dataSetsInFirstPeriod;

  const indices = {
                    'index': index, 'point_index': point_index,
                    'period': period, 'dataSetsInFirstPeriod': dataSetsInFirstPeriod,
                    'currency': currency, 'unit': unit
                  }

  addToolTipContainer(dataPoints, toolTipContainer, indices);
  addToolTipIndicatorAbbr(dataPoints, indicatorAbbr, indicatorName, indices)
  addToolTipDate(dataPoints, toolTipDate, indices);
  addToolTipValue(dataPoints, toolTipValue, indices);

  newSvgElement.appendChild(toolTipContainer);
  newSvgElement.appendChild(indicatorAbbr);
  newSvgElement.appendChild(toolTipDate);
  newSvgElement.appendChild(toolTipValue);
}

function indicatorNameAbbr(name){
  const indicatorAbbr = {
    'Turnover': 'Turnover',
    'Overheads': 'Overheads',
    'Costs of Sale': 'COS',
    'Net Profit Before Tax': 'NPBT'
  }
  return indicatorAbbr[name];
}

function addBaselineLegend(newSvgElement) {
  var dot = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
  var text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

  dot.setAttribute('stroke', '#EC7405');
  dot.setAttribute('fill', '#EC7405');
  dot.setAttribute('cx', '156');
  dot.setAttribute('cy', '64');
  dot.setAttribute('r', '0.5');

  text.setAttribute('line-height', '8');
  text.setAttribute('font-size', '3');
  text.setAttribute('font-weight', '400');
  text.setAttribute('x', '158');
  text.setAttribute('y', '65');
  text.innerHTML = 'Baseline';

  newSvgElement.appendChild(dot);
  newSvgElement.appendChild(text);
}

function getBaselineValues(data) {
  return data['baseline'];
}

export {
          drawYOrigin, initialXValue, highestYValue, addNewGraph, addYvalueLines,
          addXmarks, addXlabels, horizontalDistanceBetweenDataPoints, yValue,
          addYlabels, addLegend, colors, unitValue, addToolTip, indicatorNameAbbr,
          anyNegativeYvalues, highestNegValue, getHighestValue, isDataAvailable,
          addBaselineLegend, getBaselineValues, bottomValue, topValue
        }
