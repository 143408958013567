import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import morphdom from 'morphdom';

import { setQueryFromForm } from '../../utils/query';

const DEBOUNCE_MILLISECONDS = 1000;
const OVERLAY_VISIBLE_CLASS = 'filter-form__overlay--visible'

/**
 * @module Users.FilterController
 * @description Controller for `Users::FilterComponent`.
 *
 */
export default class extends Controller {
  static targets = ['form', 'searchInput', 'spinner', 'error', 'column'];

  connect() {
    this.searchInputTarget.select();
    this.fireSubmit = this.fireSubmit.bind(this);
    this.turbolinksBeforeVisit = this.turbolinksBeforeVisit.bind(this);
    document.addEventListener('turbolinks:before-visit', this.turbolinksBeforeVisit, false);
    this.alignSecondColumn()
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-visit', this.turbolinksBeforeVisit);
    this.searchInputTarget.value = '';
  }

  /** Handler for form ajax:loading event */
  loading() {
    setQueryFromForm(this.formTarget);
    this.showSpinner();
  }

  /**
   * Handler for form ajax:success event
   * @param {event} event the event from the form
   */
  success({ detail: [response] }) {
    morphdom(this.element, response.body.children[0]);
  }

  /** Handler for form ajax:error event */
  error() {
    this.showError();
  }

  /** Handler for form ajax:complete event */
  complete() {
    this.alignSecondColumn()
  }

  /** Submit the form immediately */
  submit() {
    setTimeout(this.fireSubmit, 0);
  }

  /** Submit the form with a debounce */
  submitDebounced() {
    if (this.submitTimeout) {
      clearTimeout(this.submitTimeout);
    }
    this.submitTimeout = setTimeout(this.fireSubmit, DEBOUNCE_MILLISECONDS);
  }

  /** Fire the submit event on the form */
  fireSubmit() {
    Rails.fire(this.formTarget, 'submit');
  }

  /** Show the loading spinner overlay */
  showSpinner() {
    this.errorTarget.classList.remove(OVERLAY_VISIBLE_CLASS);
    this.spinnerTarget.classList.add(OVERLAY_VISIBLE_CLASS);
  }

  /** Show the error message overlay */
  showError() {
    this.spinnerTarget.classList.remove(OVERLAY_VISIBLE_CLASS);
    this.errorTarget.classList.add(OVERLAY_VISIBLE_CLASS);
  }

  /** Handler for the turbolinks:before-visit event */
  turbolinksBeforeVisit({ data: { url }}) {
    url = new URL(url);
    if (url.pathname !== location.pathname) return;
    this.showSpinner();
  }

  alignSecondColumn() {
    var first_columns = document.querySelectorAll('.table__th-fixed');

    var second_col_width = first_columns[0].offsetWidth

    document.querySelectorAll('.table__th-fixed:nth-child(2)')[0].style.left = second_col_width + "px";
    var second_columns = document.querySelectorAll('.table__td-fixed:nth-child(2)');
    for(var i = 0; i < second_columns.length; i++){
      second_columns[i].style.left = second_col_width + "px";
    }
  }
}
