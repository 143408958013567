import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

const ACTIVE_FORM_CLASS = 'ticket-reply-form__form--active';

/**
 * @module Tickets.ReplyFormController
 * @description Controller for `Tickets::ReplyFormComponent`.
 *
 */
export default class extends Controller {
  static targets = ['form', 'textarea'];

  /**
   * Change the current reply option
   * @param {string} value - the reply option to change to
   */
  set option(value) {
    this.formTargets.forEach(form => {
      if (form.dataset.scope === value) {
        form.classList.add(ACTIVE_FORM_CLASS);
        Rails.fire(form.querySelector('textarea'), 'resize'); // trigger autoresize
      } else {
        form.classList.remove(ACTIVE_FORM_CLASS);
      }
    });
  }

  /**
   * Update all the reply forms textareas to match what the user currenty typed in
   * @param {event} event - the event from the textarea that the user is typing into
   */
  updateText({ target }) {
    this.textareaTargets.forEach(textarea => {
      if (textarea === target) return;

      textarea.value = target.value;
    });
  }

  /** Handler for when the reply option select is changed */
  changeOption({ target: { selectedOptions: [option, ] } }) {
    this.option = option.value;
  }
}
