import { Controller } from 'stimulus';

const HIGHLIGHTED_CLASS = 'search-select__item--highlighted';
const OPEN_LIST_CLASS = 'search-select__list--open';

/**
 * @module SearchBase
 * @description
 *   Base functionality for typeahead-like functionality.
 *   e.g. `MultiselectController` and `SearchSelectController`
 *
 */
export default class extends Controller {
  static targets = ['input', 'list', 'item'];

  /** @returns {string} Property name used to identify results */
  get key() {
    return this.data.get('key');
  }

  /** @returns {string} Property name used to display results */
  get display() {
    return this.data.get('display');
  }

  /** @returns {string} The current string typed in by the user */
  get query() {
    return this.inputTarget.value;
  }

  /** @returns {number} Index of the currently highlighted item in the reuslt list */
  get highlightedIndex() {
    let item = this.highlightedItem;
    if (!item) return null;
    return parseInt(item.dataset.index, 10);
  }

  /**
   * Set the index of the currently highlighted item in the reuslt list
   * @param {number} index - the index to highlight
   */
  set highlightedIndex(index) {
    if (index < 0) index = 0;
    if (index >= this.itemTargets.length) index = this.itemTargets.length - 1;
    if (!this.itemTargets.length) index = null;

    this.data.set('highlightedIndex', index);
    this.itemTargets.forEach(item => {
      if (item.dataset.index === `${index}`) item.classList.add(HIGHLIGHTED_CLASS);
      else item.classList.remove(HIGHLIGHTED_CLASS);
    });

    if (this.highlightedItem) {
      this.highlightedItem.scrollIntoView({ block: 'nearest' });
    }
  }

  /** @returns {HTMLElement} Currently highlighted item in the result list */
  get highlightedItem() {
    return this.itemTargets.find(item => item.dataset.index === this.data.get('highlightedIndex'));
  }

  /**
   * Set the currently highlighted item in the result list
   * @param {HTMLElement} item - element to highlight
   */
  set highlightedItem(item) {
    this.highlightedIndex = item && item.dataset.index;
  }

  connect() {
    this.onClickOutside = this.onClickOutside.bind(this);

    document.body.addEventListener('click', this.onClickOutside, false);
  }

  disconnect() {
    document.body.removeEventListener('click', this.onClickOutside);
  }

  /**
   * Override this in base class to filter items from the results list
   * @param {Object} item - the item to filter
   */
  filter(item) {
    return true;
  }

  /**
   * Handler for when the results should be fetched.
   * i.e. on the `input` event of the typeahead input element.
   */
  search() {
    if (!this.query) {
      this.showList();
      return;
    }

    this.fetchData(this.query)
      .then(data => data.filter(item => this.filter(item)))
      .then(data => {
        if (data.length) {
          let newList = data
            .map((item, index) => `
              <li class="search-select__item"
                  data-target="${this.identifier}.item"
                  data-id="${item[this.key]}"
                  data-index="${index}"
                  data-action="click->${this.identifier}#select mouseenter->${this.identifier}#highlight"
                >${item[this.display]}</id>
            `)
            .join('');
          this.showList(newList);
          this.highlightedIndex = 0;
        } else {
          this.showList(`
            <li class="search-select__item search-select__item--no-results">
              No results
            </li>
          `);
        }
      })
      .catch(error => {
        if (error instanceof DOMException && error.name === "AbortError") return;


        this.showList(`
          <li class="search-select__item search-select__item--error">
            There was an error, try again later
          </li>
        `);
      });
  }

  /**
   * Handler for when a user hovers over an item in the results list
   * @param {event} event - The hover event from the item
   */
  highlight(event) {
    this.highlightedItem = event.currentTarget;
  }

  /**
   * Handler for keypresses on the typeahead input
   * @param {event} event - the event from the input
   */
  keypress(event) {
    switch (event.which) {
      case 9: // TAB
        this.onBlur();
        return;
      case 13: // ENTER
        this.select({ currentTarget: this.highlightedItem });
        break;
      case 38: // UP
        this.highlightedIndex -= 1;
        break;
      case 40: // DOWN
        this.highlightedIndex += 1;
        break;
      default: return;
    }

    event.preventDefault();
  }

  /** Focus the input */
  focus() {
    this.inputTarget.select();
    this.search();
  }

  /** Un-focus the input */
  blur() {
    this.inputTarget.blur();
    this.onBlur();
  }

  /** Handler for when input loses focus */
  onBlur() {
    this.closeList();
  }

  /** Handler for when the user clicks outside the results list */
  onClickOutside(event) {
    if (this.element.contains(event.target)) return;

    this.blur();
  }

  /** Closes the result list */
  closeList() {
    this.listTarget.innerHTML = '';
    this.listTarget.classList.remove(OPEN_LIST_CLASS);
  }

  /**
   * Show the list, optionally with the given content.
   * @param {string} contents - content to place in the list. Defaults to "Start typing..."
   */
  showList(contents = null) {
    this.listTarget.innerHTML = contents || `
      <li class="search-select__item search-select__item--no-results">
        Start typing...
      </li>
    `;
    this.listTarget.classList.add(OPEN_LIST_CLASS);
  }

  /**
   * Fetch results from the server
   * @param {string} queryString - the query string to filter by
   */
  fetchData(queryString) {
    if (this.abortController) this.abortController.abort();
    this.abortController = this.controller = new AbortController();

    let url = this.appendQuery(this.data.get('path'), `query_string=${encodeURIComponent(queryString)}&limit=10`);
    let options = { signal: this.abortController.signal };

    return fetch(url, options)
      .then(response => response.json());
  }

  /**
   * Appends query to the given path, taking into account that the path may already have a query string
   * TODO: this could be moved to a utility function.
   * @param {string} path - a url or path
   * @param {string} query - a url query string
   */
  appendQuery(path, query) {
    if (path.includes('?')) {
      return `${path}&${query}`;
    } else {
      return `${path}?${query}`;
    }
  }
}
