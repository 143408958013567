import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import { setUrlProperty } from '../../utils/query';
import { setContent, setContentWithTemplate } from '../../utils/setContent';

/**
 * @module Tickets.FilterController
 * @description Controller for `Tickets::FilterComponent`.
 *
 */
export default class extends Controller {
  static targets = ['form', 'select', 'content', 'loadingTemplate', 'errorTemplate', 'allParameter'];

  /** @returns {string} name of the filter parameter */
  get param() {
    return this.selectTarget.name;
  }

  /** @returns {string} value of the currently selected filter option */
  get filter() {
    return this.selectTarget.selectedOptions[0].value;
  }

  /** Handler for ajax:loading on the form */
  loading() {
    setContentWithTemplate(this.contentTarget, this.loadingTemplateTarget);
  }

  /**
   * Handler for ajax:success on the form
   * @param {event} event - the ajax event
   */
  success({ detail: [response] }) {
    setContent(this.contentTarget, ...response.body.children);
  }

  /** Handler for ajax:error on the form */
  error() {
    setContentWithTemplate(this.contentTarget, this.errorTemplateTarget);
  }

  /** Handler for ajax:complete on the form */
  complete() {
    this.removeAllParameter();
  }

  /** Submit the filter form */
  submit() {
    setUrlProperty(this.param, this.filter);
    Rails.fire(this.formTarget, 'submit');
  }

  /** Request all results be submitted instead of a limited subset */
  viewAll() {
    this.addAllParameter();
    this.submit();
  }

  /** Remove the `all` parameter used by `viewAll` */
  removeAllParameter() {
    if (!this.hasAllParameterTarget) return;
    this.allParameterTarget.remove();
  }

  /** Add `all` parameter used by `viewAll` */
  addAllParameter() {
    let allParam = document.createElement('input');
    allParam.type = 'hidden';
    allParam.name = 'all';
    allParam.value = '1';
    allParam.dataset.target = `${this.identifier}.allParameter`;
    this.formTarget.append(allParam);
  }
}
