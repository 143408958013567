import { Controller } from 'stimulus';

const PROMPT_CLASS = 'select--prompt'

/**
 * @module SelectController
 * @description
 *   Controller for `SelectComponent`.
 *   Toggles a class based on whether or not the current item is a "prompt" item or not
 *
 */
export default class extends Controller {
  /** @returns {HTMLOptionElement} the currently selected option */
  get selected() {
    return this.element.selectedOptions[0];
  }

  connect() {
    this.update();
  }

  /** Handler for when an item is selected */
  update() {
    let selected = this.selected;
    if (selected && selected.dataset.prompt !== undefined) {
      this.element.classList.add(PROMPT_CLASS);
    } else {
      this.element.classList.remove(PROMPT_CLASS);
    }
  }
}
