import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['formBody', 'selectInputs',
                    'wrapUpInput', 'typeOfFundingInput',
                    'typeOfDebtFundingInput', 'submitInput',
                    'industryDetails', 'industryDetailsOptions',
                    'industry'];

  connect(){
    if (this.industryTarget.options[this.industryTarget.selectedIndex].innerText != 'Please Select...') {
      this.industryDetailsTarget.classList.remove('hide');
    }
  }

  toggleReportForm() {
    var childrenArr = this.formBodyTarget.children;
    var selectInputs = this.selectInputsTargets;

    for (var i = 0; i < childrenArr.length; i++) {
      childrenArr[i].classList.remove('report-input-disabled');
    }

    for (var i = 0; i < selectInputs.length; i++) {
      selectInputs[i].removeAttribute('disabled');
    }

    this.submitInputTarget.classList.remove('report-input-disabled');
  }

  handleRichText(event) {
    event.target.parentElement.children[0].value = event.target.innerHTML
  }

  handleSessionNumber(event) {
    var childrenArr = this.formBodyTarget.children;

    if (event.target.options[event.target.selectedIndex].innerText == 'Wrap Up Session') {
      this.wrapUpInputTarget.value = 'true';
      event.target.options[event.target.selectedIndex].value = 99;

      for (var i = 0; i < childrenArr.length; i++) {
        if (childrenArr[i].classList.contains('standard-session-input')) {
          childrenArr[i].classList.add('hide');
        }

        if (childrenArr[i].classList.contains('completion-session-input')) {
          childrenArr[i].classList.add('hide');
        }

        if (childrenArr[i].classList.contains('comment-field')) {
          childrenArr[i].classList.remove('hide');
        }
      }
    } else {
      this.wrapUpInputTarget.value = 'false';

      for (var i = 0; i < childrenArr.length; i++) {
        if (childrenArr[i].classList.contains('standard-session-input')) {
          childrenArr[i].classList.remove('hide');
        }

        if (childrenArr[i].classList.contains('completion-session-input')) {
          childrenArr[i].classList.add('hide');
        }
      }
    }
  }

  handleFinancialFields(event) {
    if (event.target.name.includes('funding_required')) {
      if (event.target.options[event.target.selectedIndex].innerText == 'Yes') {
        this.typeOfFundingInputTarget.classList.remove('hide');
      } else {
        this.typeOfFundingInputTarget.classList.add('hide');
        if (!this.typeOfDebtFundingInputTarget.classList.contains('hide')) {
          this.typeOfDebtFundingInputTarget.classList.add('hide');
        }
      }
    }

    if (event.target.name.includes('type_of_funding')) {
      if (event.target.options[event.target.selectedIndex].innerText == 'Debt Funding') {
        this.typeOfDebtFundingInputTarget.classList.remove('hide');
      } else {
        this.typeOfDebtFundingInputTarget.classList.add('hide');
      }
    }
  }

  handleIndustryDetails(event) {
    if (event.target.options[event.target.selectedIndex].innerText.includes('Please Select...')) {
      if (!this.industryDetailsTarget.classList.contains('hide')) {
        this.industryDetailsTarget.classList.add('hide');
      }
    } else {
      if (this.industryDetailsTarget.classList.contains('hide')) {
        this.industryDetailsTarget.classList.remove('hide');
      }

      this.filterIndustryDetail();
    }
  }

  filterIndustryDetail(){
    for (var i = 0; i < this.industryDetailsOptionsTarget.options.length; i++) {
      var industry = event.target.value.split(' ')[0];
      var selectOption = this.industryDetailsOptionsTarget.options[i];

      if (!selectOption.value.includes(industry)) {
        selectOption.classList.contains('hide') ? '' : selectOption.classList.add('hide');
      } else {
        selectOption.classList.contains('hide') ? selectOption.classList.remove('hide') : '';
      }
    }
  }

}
