import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

import Claim from './models/claim';
import ApiRequest from './api/request';

const OVERLAY_VISIBLE_CLASS = 'claim__overlay--visible';

export default class extends Controller {
  static targets = [
    'form',
    'claimId',
    'claimStatus',
    'claimSubmittedAt',
    'claimedActivityId',
    'accreditationLevelId',
    'userClaimId',
    'userId',
    'companyId',
    'activityId',
    'claimComment',
    'claimDetailWrapper',
    'referringTo',
    'sessionNumber',
    'activityStatus',
    'outOfTown',
    'accreditationLevelName',
    'paymentActivity',
    'paymentOutOfTown',
    'paymentActivityStatus',
    'paymentAdditionalExpenses',
    'paymentAmount',
    'paymentSubTotal',
    'spinner',
    'submitDraft',
    'submit',
    'additionalExpenseWrapper',
    'additionalExpense'
  ]

  connect() {
    this.element[this.camelize(this.identifier)] = this;
    this.enableSubmitButton();
  }

  saveClaimWithAccreditation(accreditationLevel) {
    this.accreditationLevelIdTarget.value = accreditationLevel.accreditation_level_id;
    this.accreditationLevelNameTarget.value = accreditationLevel.accreditation_level_name;

    this.saveClaim(this.claimModel());
  }

  saveClaim(claim) {
    claim.save().then((updatedClaim) => {
      this.updateFormFields(updatedClaim);
      this.complete();
    })
  }

  claimModel() {
    let claimAttributes = {
      id: this.claimIdTarget.value,
      company_id: this.companyIdTarget.value,
      status: this.claimStatusTarget.value,
      submitted_at: this.claimSubmittedAtTarget.value,
      comments: this.claimCommentTarget.value,
      additional_expenses: [],
      claimed_activities: this.claimedActivities(),
      user_claims: this.userClaims(),
      value_summary: this.valueSummary()
    }

    return new Claim(claimAttributes);
  }

  claimedActivities() {
    let claimedActivities = [
      {
        id: this.claimedActivityIdTarget.value,
        claim_id: this.claimIdTarget.value,
        activity_id: this.activityIdTarget.value,
        accreditation_level_id: this.accreditationLevelIdTarget.value,
        activity_status_id: this.activityStatusTarget.value,
        out_of_town: this.outOfTownTarget.value,
        session_number: this.sessionNumberTarget.value,
        referring_to: this.referringToTarget.value,
        claimed_additional_expenses: this.additionalExpenses()
      }
    ]

    return claimedActivities;
  }

  userClaims() {
    let userClaims = [
      {
        id: this.userClaimIdTarget.value,
        user_id: this.userIdTarget.value,
        claim_id: this.claimIdTarget.value
      }
    ]

    return userClaims;
  }

  fetchAccreditationLevel() {
    let request = new ApiRequest();
    let options = {
      path: 'user_accreditation_level',
      params: {
        user_id: this.userIdTarget.value,
        activity_id: this.activityIdTarget.value,
        company_id: this.companyIdTarget.value
      }
    }

    return request.queryTimesheets(options);
  }

  valueSummary() {
    return {
      activity: this.paymentActivityTarget.innerText,
      activity_status: this.paymentActivityStatusTarget.innerText,
      additional_expenses: this.paymentAdditionalExpensesTarget.innerText,
      out_of_town: this.paymentOutOfTownTarget.innerText
    }
  }

  /** CLAIM UPDATE METHODS
   * Handles updates to view and input elements
   */

  /**
   * Entry: callback method, responsible for managing claim form interactions
   */
  updateClaim(event) {
    this.loading();

    if (typeof(event) != 'undefined') {
      let eventTarget = event.currentTarget.dataset.target;

      if (eventTarget == 'timesheets--claim.submitDraft' || eventTarget == 'timesheets--claim.submit') {
        event.preventDefault();

        if (event.currentTarget.value == 'save_and_submit') {
          this.claimStatusTarget.value = 'submitted';
        }
      }
    }

    if (this.userIdTarget.value && this.activityIdTarget.value) {
      this.fetchAccreditationLevel()
        .then(resp => this.saveClaimWithAccreditation(resp))
        .catch(error => console.log(error));
    } else {
      this.saveClaim(this.claimModel());
    }
  }

  /**
   * Source update method, responsible for all form elements updates
   * @param {claim} - claim object returned from the claims controller
   */
  updateFormFields(claim) {
    this.claimIdTarget.value = claim.id;
    this.userClaimIdTarget.value = claim.userClaims[0].id;
    this.claimedActivityIdTarget.value = claim.claimedActivities[0].id;
    this.claimStatusTarget.value = claim.status;

    this.updatePaymentSummary(claim);
    this.updateAdditionalExpenses(claim);
    this.enableSubmitButton();
  }

  /**
   * Updates elements related to claim payment summary
   * @param {claim} - claim object returned from the claims controller
   */
  updatePaymentSummary(claim) {
    this.paymentActivityTarget.innerHTML = claim.valueSummary.activity;
    this.paymentOutOfTownTarget.innerHTML = claim.valueSummary.outOfTown;
    this.paymentActivityStatusTarget.innerHTML = claim.valueSummary.activityStatus;
    this.paymentAdditionalExpensesTarget.innerHTML = claim.valueSummary.additionalExpenses;
    this.paymentAmountTarget.innerHTML = claim.valueSummary.amountDue;
    this.paymentSubTotalTarget.innerHTML = claim.valueSummary.amountDue;
  }

  /** ADDITIONAL EXPENSES METHODS
   * Handles Additional expenses requests and view updates
   */

   checkedAdditionalExpenses() {
     let additionalExpenses = [];
     let userSpecifiedExpenses = [];

     this.additionalExpenseTargets.forEach(ae => {
       if (ae.checked) {
         let unit = ae.dataset.additionalExpenseUnit;

         if (unit != 'value_multiplier' && unit != 'user_specified') {
           additionalExpenses.push(ae);
         }
         else {
           userSpecifiedExpenses.push(ae);
         }
       }
     })

     return [additionalExpenses, userSpecifiedExpenses]
   }

   notCheckedAdditionalExpenses() {
     let notChecked = [];

     this.additionalExpenseTargets.forEach(ae => {
       if (!ae.checked) {
         notChecked.push(ae);
       }
     })

     return notChecked;
   }

   claimedAddObject(addExp) {
     let value = '';
     let id = '';
     let unit = addExp.dataset.additionalExpenseUnit;
     let claimedAddExpenseField = this.claimedAddExpenseField(addExp.value);

     if (claimedAddExpenseField) {
       value = claimedAddExpenseField.querySelector('input').value;
       id = claimedAddExpenseField.dataset.claimedAdditionalExpenseId;
     }

     if (unit != 'user_specified' && unit != 'value_multiplier') {
       value = addExp.dataset.additionalExpenseExpenseRate;
     }

     let addObject = {
       id: id,
       additional_expense_id: addExp.value,
       claimed_activity_id: this.claimedActivityIdTarget.value,
       value: value
     };

     return addObject;
   }

  /** @returns {array} of user claimed additional expenses objects */
  additionalExpenses() {
    let additionalExpenses = this.checkedAdditionalExpenses()[0];
    let userSpecifiedExpenses = this.checkedAdditionalExpenses()[1];
    let notCheckedAdditionalExpenses = this.notCheckedAdditionalExpenses();
    let claimedAdditionalExpenses = [];

    // Iterate through the unchecked elements
    notCheckedAdditionalExpenses.forEach(addExp => {
      if (this.containsClaimedAddExpenseField(addExp.value)) {
        let claimedAddObject = this.claimedAddObject(addExp);
        claimedAddObject = Object.assign(claimedAddObject, {destroy: true})

        this.deleteClaimedAddExpenseField(addExp.value)
        claimedAdditionalExpenses.push(claimedAddObject);
      }
    });


    // Iterate through the checked elements
    additionalExpenses.forEach(addExp => {
      let claimedAddObject = this.claimedAddObject(addExp);
      claimedAdditionalExpenses.push(claimedAddObject);
    });

    // Iterate through user specfied elements
    userSpecifiedExpenses.forEach(addExp => {
      if (this.containsClaimedAddExpenseField(addExp.value)) {
        let claimedAddExpenseField = this.claimedAddExpenseField(addExp.value);

        if (claimedAddExpenseField.querySelector('input').value) {
          let claimedAddObject = this.claimedAddObject(addExp);

          claimedAddExpenseField.querySelector('input').classList.remove('additional-expense__input-required--red');
          claimedAdditionalExpenses.push(claimedAddObject);
        }
      } else {
        let attributes = {
          additional_expense_id: addExp.value,
          additional_expense_unit: addExp.dataset.additionalExpenseUnit,
          additional_expense_unit_type: addExp.dataset.additionalExpenseUnitType,
          additional_expense_display_name: addExp.dataset.additionalExpenseDisplayName,
          additional_expense_expense_rate: addExp.dataset.additionalExpenseExpenseRate
        }

        this.createClaimedAddExpenseField(attributes);
      }
    });

    return claimedAdditionalExpenses;
  }

  /**
   * Set the name of the selected additional Expense in the view
   * @param {name} - name of AdditionalExpense
   * @param {unit_type} - unit_type of AdditionalExpense
   */
  additionalExpenseDisplayName(name, unit_type) {
    return name + ' ' + '(' + unit_type + ')';
  }

  /**
   * Updates additional expenses view with latest results from claim object
   * @param {claim} - claim object returned from the claims controller
   */
  updateAdditionalExpenses(claim) {
    var claimedAdditionalExpenses = claim.claimedActivities[0].claimedAdditionalExpenses;
    var additionalExpenses = claim.additionalExpenses;

    if (claimedAdditionalExpenses.length > 0) {
      for (let i = 0; i < claimedAdditionalExpenses.length; i++) {
        let claimedAdditionalExpense = claimedAdditionalExpenses[i];
        let additionalExpense = additionalExpenses[claimedAdditionalExpense.additionalExpenseId];
        let additionalExpenseId = claimedAdditionalExpense.additionalExpenseId;

        let attributes = {
          claimed_additional_expense_id: claimedAdditionalExpense.id,
          additional_expense_id: additionalExpenseId,
          additional_expense_unit: additionalExpense.unit,
          additional_expense_unit_type: additionalExpense.unit_type,
          additional_expense_display_name: this.additionalExpenseDisplayName(additionalExpense.name, additionalExpense.unit_type),
          additional_expense_expense_rate: additionalExpense.expense_rate,
        }

        if (this.containsClaimedAddExpenseField(additionalExpenseId)) {
          this.updateClaimedAddExpenseField(additionalExpenseId, attributes);
        } else {
          this.createClaimedAddExpenseField(attributes);
        }
      }
    }
  }

  claimedAddExpenseField(additionalExpenseId) {
    return this.additionalExpenseWrapperTarget.querySelector(`[data-additional-expense-id="${additionalExpenseId}"]`);
  }

  /**
   * creates an input for a claimed expense
   * @param {attributes} - object containing field data
   */
  createClaimedAddExpenseField(attributes) {
    var claimedExpenseDiv = document.createElement('div');
    var labelElement = document.createElement('label');
    var inputElement = document.createElement('input');

    labelElement.setAttribute('for', `claimed_expense_${attributes.additional_expense_id}`);
    labelElement.innerText = attributes.additional_expense_display_name;

    inputElement.setAttribute('id', `claimed_expense_${attributes.additional_expense_id}`);
    inputElement.setAttribute('name', 'additionalExpenseId');

    if (attributes.additional_expense_unit != 'user_specified' && attributes.additional_expense_unit != 'value_multiplier') {
      inputElement.setAttribute('value', attributes.additional_expense_expense_rate);
      inputElement.setAttribute('readonly', 'readonly');
    } else {
      inputElement.setAttribute('data-action', 'blur->timesheets--claim#updateClaim');
      inputElement.classList.add('additional-expense__input-required--red');
    }

    claimedExpenseDiv.classList.add('additional-expense__input');
    claimedExpenseDiv.setAttribute('data-claimed-additional-expense-id', (attributes.claimed_additional_expense_id || ''));
    claimedExpenseDiv.setAttribute('data-additional-expense-id', (attributes.additional_expense_id || ''));
    claimedExpenseDiv.setAttribute('data-claimed-activity-id', (this.claimedActivityIdTarget.value || ''));

    claimedExpenseDiv.appendChild(labelElement);
    claimedExpenseDiv.appendChild(inputElement);

    this.additionalExpenseWrapperTarget.appendChild(claimedExpenseDiv);
  }

  /**
   * deletes an input for a claimed expense
   * @param {additionalExpenseId} - string
   */
  deleteClaimedAddExpenseField(additionalExpenseId) {
    let childElements = this.additionalExpenseWrapperTarget.querySelectorAll(`[data-additional-expense-id="${additionalExpenseId}"]`);
    childElements.forEach(element => {
      this.additionalExpenseWrapperTarget.removeChild(element);
    });
  }

  /**
   * updates inputs for a claimed expense
   * @param {additionalExpenseId} - string
   * @param {attributes} - object containing field data
   */
  updateClaimedAddExpenseField(additionalExpenseId, attributes) {
    let element = this.additionalExpenseWrapperTarget.querySelector(`[data-additional-expense-id="${additionalExpenseId}"]`);
    element.dataset.claimedAdditionalExpenseId = (attributes.claimed_additional_expense_id || '');
  }

  /**
   * checks if `Wrapper div element` contains input for a claimed expense
   * @param {additionalExpenseId} - string
   * @return {boolean}
   */
  containsClaimedAddExpenseField(additionalExpenseId) {
    let childElement = this.additionalExpenseWrapperTarget.querySelector(`[data-additional-expense-id="${additionalExpenseId}"]`)
    return this.additionalExpenseWrapperTarget.contains(childElement);
  }

  /** HELPER METHODS */

  /** updates view to a loading state */
  loading() {
    this.showSpinner();
    this.disableForm();
  }

  /** updates view to a complete state */
  complete() {
    this.removeSpinner();
    this.enableForm();
  }

  /** adds loader */
  showSpinner() {
    this.spinnerTarget.classList.add(OVERLAY_VISIBLE_CLASS);
  }

  /** removes loader */
  removeSpinner() {
    this.spinnerTarget.classList.remove(OVERLAY_VISIBLE_CLASS);
  }

  /** disables submit buttons */
  disableForm() {
    this.submitDraftTarget.disabled = true;
    this.submitTarget.disabled = true;
  }

  /** enables submit buttons */
  enableForm() {
    if (this.claimStatusTarget.value == 'draft') {
      this.submitDraftTarget.disabled = false;
    }

    if (this.claimStatusTarget.value != 'draft') {
      this.toggleInputDisable(this.claimDetailWrapperTarget, true);
    }
  }

  /**
   * Toggles input disable of all the input of a given parent element
   * @params {parent} - the parent element
   * @params {disable} - the parent element
  */

  toggleInputDisable(parent, disable) {
    let elements = Array.from(parent.querySelectorAll('input, select'));
    let index = elements.indexOf(this.accreditationLevelNameTarget);

    if (index > -1) { elements.splice(index, 1) }

    this.formTarget.querySelector('.multi-select').dataset.disable = `${disable}`;

    elements.forEach(input => {
      input.disabled = disable
    });
  }

  /** @returns {string} - used to convert controller name to camelCase */
  camelize(str) {
    var str = str.split('--');
    str[1] = str[1].charAt(0).toUpperCase() + str[1].slice(1)
    str = str.join('');

    return str;
  }

  enableSubmitButton() {
    if (this.claimStatusTarget.value == 'draft') {
      let validClaimProps = [
        this.claimIdTarget.value,
        this.referringToTarget.value,
        this.sessionNumberTarget.value,
        this.activityStatusTarget.value,
        this.outOfTownTarget.value
      ];

    let enable = validClaimProps.every(prop => prop != '');

    if (enable) { this.submitTarget.disabled = false }
    }
  }
}
