import SearchBase from '../search_base';

export default class extends SearchBase {
  static targets = [...SearchBase.targets, 'output'];

  get claimForm() {
    return document.querySelectorAll("[data-target='timesheets--claim.form']")[0];
  }

  get selectedId() {
    return this.outputTarget.value;
  }

  set selectedId(id) {
    this.outputTarget.value = id;
  }

  get selectedName() {
    return this.data.get('value') || '';
  }

  set selectedName(name) {
    this.data.set('value', name || '');
    this.inputTarget.value = name;
  }

  connect() {
    super.connect();

    this.inputTarget.value = this.selectedName;
  }

  onBlur() {
    super.onBlur();
    if (!this.inputTarget.value) {
      this.clearSelection();
    } else {
      this.inputTarget.value = this.selectedName;
    }
  }

  clearSelection() {
    this.selectedId = null;
    this.selectedName = null;
  }

  select(event) {
    this.selectedId = event.currentTarget.dataset.id;
    this.selectedName = event.currentTarget.innerText;
    this.closeList();
    this.inputTarget.blur();

    this.claimForm.timesheetsClaim.updateClaim();
  }
}
