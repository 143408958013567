import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dateinput', 'editbutton', 'savebutton']

  toggle(e) {
    if (e.currentTarget == this.editbuttonTarget) {
      e.preventDefault();
      this.toggleEdit();
    }

    if (e.currentTarget == this.savebuttonTarget) {
      this.toggleSave();
    }
  }

  toggleEdit() {
    this.editbuttonTarget.classList.add('hide');
    this.dateinputTarget.classList.remove('disabled');
    this.dateinputTarget.nextSibling.classList.remove('disabled')
    this.savebuttonTarget.classList.remove('hide');
  }

  toggleSave() {
    this.savebuttonTarget.classList.add('hide');
    this.dateinputTarget.classList.add('disabled');
    this.dateinputTarget.nextSibling.classList.add('disabled')
    this.editbuttonTarget.classList.remove('hide');
  }
}