import ClaimedActivity from './claimedActivity';
import ApiRequest from '../api/request';
import UserClaim from './userClaim';

class Claim {
  constructor(claim) {
    this.setAttributes(claim);
    this.setErrors({});
  }

  setAttributes(claim) {
    const summary = claim.value_summary;

    this.id = claim.id;
    this.company = claim.company || { id: claim.company_id };
    this.status = claim.status;
    this.submittedAt = claim.submitted_at;
    this.comments = claim.comments;
    this.claimedActivities = claim.claimed_activities.map(cA => new ClaimedActivity(cA));
    this.userClaims = claim.user_claims.map(uC => new UserClaim(uC));
    this.additionalExpenses = claim.additional_expenses;
    this.valueSummary = {
      activity: summary.activity,
      outOfTown: summary.out_of_town,
      activityStatus: summary.activity_status,
      additionalExpenses: summary.additional_expenses,
      amountDue: summary.amount_due
    };
  }

  setErrors(errors) {
    this.errors = errors || {};
  }

  attributes() {
    return {
      id: this.id,
      company_id: this.company.id,
      status: this.status,
      submitted_at: this.submittedAt,
      comments: this.comments,
      claimed_activities_attributes: this.claimedActivities.map(cA => cA.attributes()),
      user_claims_attributes: this.userClaims.map(uC => uC.attributes())
    };
  }

  save() {
    const request = new ApiRequest();
    const params = { claim: this.attributes() };

    if (!this.id) {
      return request.createClaim(params)
        .then(res => this.handleNetworkResponse(res))
        .catch(error => console.log(error));
    }

    return request.updateClaim(params)
      .then(res => this.handleNetworkResponse(res))
      .catch(error => console.log(error));
  }

  handleNetworkResponse(res) {
    this.setErrors(res.errors);
    this.setAttributes(res.claim);

    return this;
  }
}

export default Claim;
