import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'form',
    'checkboxes',
    'selectedboxes',
    'checkboxes',
    'selectedListCardWrapper',
    'selectedListCard'
  ]

  connect() {
    this.setSelectedChexboxes();
    this.setSelectedListCard();
  }

  get selectedLabelInputs() {
    var labels = this.checkboxesTarget.children;
    var selectedLabels = [];

    for (var i = 0; i < labels.length; i++) {
      if (labels[i].children[0].checked) {
        selectedLabels.push([labels[i].innerText, labels[i].children[0].value]);
      }
    }

    return selectedLabels;
  }

  showCheckboxes() {
    var checkboxes = this.checkboxesTarget;
    var closed = checkboxes.classList.contains('hide');
    var disabled = this.formTarget.dataset.disable;

    if (disabled != 'false') {
      if (!closed) { checkboxes.classList.add('hide') }
    } else {
      if (closed) {
        checkboxes.classList.remove('hide');
      } else {
        checkboxes.classList.add('hide');
      }
    }
  }

  handleChecked() {
    this.setSelectedChexboxes();
    this.setSelectedListCard();
  }

  setSelectedChexboxes() {
    var selectedLabels = this.selectedLabelInputs;
    this.selectedboxesTarget.innerHTML = '';

    for (var i = 0; i < selectedLabels.length; i++) {
      var span = document.createElement('span');
      span.innerText = selectedLabels[i][0];

      this.selectedboxesTarget.appendChild(span);
    }
  }

  setSelectedListCard() {
    if (this.selectedListCardTarget.dataset.display == "true") {
      var selectedLabels = this.selectedLabelInputs;

      this.selectedListCardWrapperTarget.classList.remove('hide');
      this.selectedListCardTarget.innerHTML = '';

      for (var i = 0; i < selectedLabels.length; i++) {
        var parent_div = document.createElement('div');
        var label_div = document.createElement('div');
        var value_div = document.createElement('div');

        label_div.innerText = selectedLabels[i][0];
        value_div.innerText = selectedLabels[i][1];

        parent_div.appendChild(label_div);
        parent_div.appendChild(value_div);

        this.selectedListCardTarget.appendChild(parent_div);
      }
    }
  }
}
