import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['status']

  toggleChecked(event) {
    if (event.target.checked === true) {
      event.target.checked = false
    }
    else {
      event.target.checked = true
    }
  }
  
  confirmSubmit(event) {
  event.preventDefault();
  const status = this.statusTarget.value
  const confirmed = confirm(`Are you sure you want to ${status === 'active' ? 'suspend' :'activate'} user?`);
  const statusUpdate = status  === 'active' ? 'suspended' : 'active'
  this.statusTarget.setAttribute('value', statusUpdate)
  if (confirmed) {
    event.target.closest('form').submit();
  }
  }
}
