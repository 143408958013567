import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['body', 'header', 'open', 'close']

  toggle() {
    this.headerTarget.classList.toggle("active");
    this.bodyTarget.style.display = this.bodyTarget.style.display === 'block' ? 'none' : 'block';
    this.openTarget.style.display = this.openTarget.style.display === 'none' ? 'block' : 'none';
    this.closeTarget.style.display = this.closeTarget.style.display === 'block' ? 'none' : 'block';
  }
}
