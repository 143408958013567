import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['red', 'teal', 'orange', 'productid', 'subscriptionplans', 'planprice', 'plansessions', 'planproductname']

  selectProduct (element) {
    this.hideSubmitButtons

    if (element.target.id.includes('teal')) {
      this.tealTargets.forEach(element => { element.classList.add("active") })
      this.redTargets.forEach(element => { element.classList.remove("active") })
      this.orangeTargets.forEach(element => { element.classList.remove("active") })

      let product_id = element.target.id.split('_')[0];

      this.productidTargets.forEach(element => {
        if (element.id == 'teal') {
          element.value = product_id;
        }
      })

      document.getElementsByClassName("active-plan")[0].classList.add("hide");
      document.getElementsByClassName("active-plan")[0].classList.remove("active-plan");
      document.getElementById(element.target.id.split('_')[0] + '_subscription-product-plans').classList.remove("hide");
      document.getElementById(element.target.id.split('_')[0] + '_subscription-product-plans').classList.add("active-plan");

      document.getElementsByClassName("subscription-plans__button--teal")[0].click();
      this.unhideSpecificSubmitButton('teal');

    }
    else if (element.target.id.includes('orange')) {
      this.orangeTargets.forEach(element => { element.classList.add("active") })
      this.redTargets.forEach(element => { element.classList.remove("active") })
      this.tealTargets.forEach(element => { element.classList.remove("active") })

      let product_id = element.target.id.split('_')[0];

      this.productidTargets.forEach(element => {
        if (element.id == 'orange') {
          element.value = product_id;
        }
      })

      document.getElementsByClassName("active-plan")[0].classList.add("hide");
      document.getElementsByClassName("active-plan")[0].classList.remove("active-plan");
      document.getElementById(element.target.id.split('_')[0] + '_subscription-product-plans').classList.remove("hide");
      document.getElementById(element.target.id.split('_')[0] + '_subscription-product-plans').classList.add("active-plan");

      document.getElementsByClassName("subscription-plans__button--orange")[0].click();
      this.unhideSpecificSubmitButton('orange');
    }
    else {
      this.redTargets.forEach(element => { element.classList.add("active") })
      this.orangeTargets.forEach(element => { element.classList.remove("active") })
      this.tealTargets.forEach(element => { element.classList.remove("active") })

      let product_id = element.target.id.split('_')[0];

      this.productidTargets.forEach(element => {
        if (element.id == 'red') {
          element.value = product_id;
        }
      })

      document.getElementsByClassName("active-plan")[0].classList.add("hide");
      document.getElementsByClassName("active-plan")[0].classList.remove("active-plan");
      document.getElementById(element.target.id.split('_')[0] + '_subscription-product-plans').classList.remove("hide");
      document.getElementById(element.target.id.split('_')[0] + '_subscription-product-plans').classList.add("active-plan");

      document.getElementsByClassName("subscription-plans__button--red")[0].click();
      this.unhideSpecificSubmitButton('red');
    }
  }

  hideSubmitButtons() {
    ['red', 'teal', 'orange'].forEach(colour => {
      document.getElementsByName(`submit-button-${colour}`)[0].classList.add('hide');
    })
  }

  unhideSpecificSubmitButton(colour) {
    document.getElementsByName(`submit-button-${colour}`)[0].classList.remove("hide");
  }

  selectPlan (elem) {
    var arr = elem.target.id.split('_');
    var id = arr[arr.length - 1];
    var childdivs = document.getElementById(id + '_plan_details').children;
    var elements = [];

    for( var i=0; i < childdivs.length; i++ ) {
      elements.push(childdivs[i]);
    }

    this.plansessionsTargets.forEach(element => { element.innerText = elements[0].innerText});
    this.planproductnameTargets.forEach(element => { element.innerText = elements[1].innerText});
    this.planpriceTargets.forEach(element => { element.innerText = elements[2].innerText});
  }

  toggleCollapse() {
    var togglable = document.getElementsByClassName('togglable')
    for( var i = 0; i < togglable.length; i++) {
      togglable[i].classList.toggle('hide')
    }
  }
}
