import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["checkbox", 'selectList'];

  connect() {
    this.renderSelectList();
  }

  toggle(event) {
    let checkboxes = this.checkboxTarget.children;
    let arrow_down = event.target.firstElementChild;
    let arrow_up = event.target.lastElementChild;

    arrow_down.classList.toggle('hide');
    arrow_up.classList.toggle('hide');

    checkboxes[0].classList.toggle('bottom-border');
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].classList.toggle('hide');
    }
  }

  renderSelectList() {
    if(typeof this.selectListTarget == 'undefined'){
      return null;
    }

    let selectList = this.selectListTarget;
    selectList.innerHTML = '';

    this.selectListTarget.classList.remove('hide');

    let count = 0;

    for (var i = 0, len = this.checkboxTarget.children.length; i < len; i++) {
      if (typeof this.checkboxTarget.children[i].children[0] == 'undefined') {
        continue;
      }

      if(this.checkboxTarget.children[i].children[0].checked) {

        var node = document.createElement("a");
        var text = document.createTextNode('- ' + this.checkboxTarget.children[i].textContent);
        node.appendChild(text);

        selectList.appendChild(node);

        count++;
      }
    }

    if(count == 0){
      this.selectListTarget.classList.add('hide');
    }
  }
}
