import { Controller } from 'stimulus';

export default class extends Controller {

  viewed(event) {
    let userNotificationId = event.target.id;

    let userNotificationClassList = document.getElementsByClassName(userNotificationId);

    var userNotification = userNotificationClassList[0];
    var userNotificationBtn = userNotificationClassList[1];

    userNotification.classList.toggle(`user-notification__item--unvisited`);
    userNotificationBtn.classList.toggle(`user-notification__action--unvisited`);

    userNotification.classList.toggle(`user-notification__item--visited`);
    userNotificationBtn.classList.toggle(`user-notification__action--visited`);
  }
}
