import Flatpickr from 'stimulus-flatpickr';

export default class extends Flatpickr {
  initialize() {
    this.config = {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: 'Y/m/d',
      allowInput: true
    }
  }
}
