import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['delink']

  toggle() {
    this.delinkTarget.classList.toggle('hide');
    
    window.location.search = ''
  }
}
