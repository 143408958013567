import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submitBtn'];

  connect() {
    this.disableSubmit();
  }

  checkSelected(event) {
    const partnerList = document.getElementsByName("partner_filter[]");
    const industryList = document.getElementsByName("multi_industry_filter[]");
    const allOptionCheckbox = document.getElementById("multi_industry_filter_all")

    const atLeastOnePartnerSelected = [...partnerList].some(el => el.checked)
    const atLeastOneIndustrySelected = [...industryList].some(el => el.checked)

    /**
     * HACK: toggles the selected options depending on whether the 'All' options is selected or not
     * if the `All` option is selected all other options are selected
     */
    if(this.isAllIndustriesOption(event.target)) {
      if (event.target.checked) {
        const options =  [...industryList].filter(el => !this.isAllIndustriesOption(el) )
        options.forEach(el => {
        if(el.checked) el.click()
        })
      }
    } else if(this.isIndustryOption(event.target)) {
      if(allOptionCheckbox.checked) allOptionCheckbox.click()
    }

    const shouldEnableSubmitBtn =  atLeastOneIndustrySelected && atLeastOnePartnerSelected
    if(shouldEnableSubmitBtn) this.enableSubmit()
    else this.disableSubmit()
  }



  disableSubmit() {
    let submitBtn = this.submitBtnTarget;
    if(submitBtn){
      submitBtn.classList.add("button--disabled");
      submitBtn.setAttribute('title', 'You must select at least one Licensee!');
      submitBtn.setAttribute('disabled','disabled');
    }
  }

  enableSubmit() {
    let submitBtn = this.submitBtnTarget;
    if(submitBtn){
      submitBtn.classList.remove("button--disabled");
      submitBtn.setAttribute('title', '');
      submitBtn.removeAttribute('disabled');
    }
  }

  isAllIndustriesOption(element){
    return ( element.id === "multi_industry_filter_all" && this.isIndustryOption)
  }

  isIndustryOption(element){
    return element.name == "multi_industry_filter[]"
  }
}
