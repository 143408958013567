import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["submitButton", "form", "errorLabel"];

  connect() {
    this.preFormatNumber();

    this.failedValidation = []

    const elements = document.getElementsByClassName('answer-element');
    this.errorLabelTarget.hidden = true;

    const _this = this;
    this.submitButtonTarget.addEventListener('click', function() {
      _this.errorLabelTarget.hidden = true;

      // Uncomment this when you need validation

      for (var i = 0; i < elements.length; i++) {
        if (elements[i].value == '' || typeof elements[i].value == 'undefined'){
          _this.errorLabelTarget.hidden = false;
          return;
        }
      }

      console.log(_this.failedValidation)

      if (_this.failedValidation.length !== 0) {
        _this.errorLabelTarget.hidden = false;
        return;
      }

      _this.formTarget.submit();
    });
  }

  preFormatNumber(){
    const textBoxes = document.querySelectorAll('.text-field-number,.text-field-currency,.text-field-percentage')

    for (var i = 0; i < textBoxes.length; i++){
      this.setInputFilter(
        textBoxes[i],
        function(value) {
          return /^-?\d*[.]?\d*$/.test(value);
        }
      );
    }
  }

  tabToNext(event) {
    if(event.key === 'Tab'){
      this.formatNumber(event)
    }
  }

  formatNumber(event) {
    let value = event.target.value.replace(',', '');
    let first = value.split('.')[0]
    let second = value.split('.')[1]
    event.target.value = first.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if (typeof second != 'undefined') {
      event.target.value += "." + second
    }
  }

  formatPercentage(event) {
    if (parseFloat(event.target.value) > 100) {
      event.target.value = '100'
    }
  }

  setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
      textbox.addEventListener(event, function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = this.value.replaceAll(' ', '');
        }
      });
    });
  }

  validateEmail(event) {
    this.validate(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, event.target)
  }

  validatePhone(event) {
    this.validate(/^(\+\d{1,2}\s?)?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, event.target)
  }

  validateUrl(event) {
    this.validate(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/, event.target)
  }

  validate(regex, textbox) {

    if (regex.test(textbox.value)){
      this.failedValidation = this.failedValidation.filter(function(value, index, arr){
          return value !== textbox.name;
      });

      textbox.classList.remove('failed-validation')
      return (true)
    }
    else {
      this.failedValidation.push(textbox.name)
      this.failedValidation = [...new Set(this.failedValidation)]

      textbox.classList.add('failed-validation')
    }
  }

}
