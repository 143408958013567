import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['open', 'modal', 'input', 'btn']

  toggleModal() {
    this.modalTarget.classList.toggle('hide');
    this.openTarget.classList.toggle('hide');
  }

  enableBtn() {
    var id = this.inputTarget.id.toLowerCase();
    var inputValue = this.inputTarget.value.toLowerCase();
    const btnClasses = this.btnTarget.classList;

    if (id == inputValue && !btnClasses.contains('active')) {
      btnClasses.add('active');
      this.btnTarget.disabled = false;
    } else if (btnClasses.contains('active')) {
      btnClasses.remove('active');
      this.btnTarget.disabled = true;
    }
  }
}
