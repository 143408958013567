import SearchBase from './search_base';

/**
 * @module SearchSelectController
 * @description Controller for `SearchSelectComponent`
 *
 */
export default class extends SearchBase {
  static targets = [...SearchBase.targets, 'output'];

  /** @returns {string} id of the currently selected item */
  get selectedId() {
    return this.outputTarget.value;
  }

  /**
   * Set the currently selected item id
   * @param {string} id - id to set to
   */
  set selectedId(id) {
    this.outputTarget.value = id;
  }

  /** @returns {string} display name of the currently selected item */
  get selectedName() {
    return this.data.get('value') || '';
  }

  /**
   * Set the currently selected item display name
   * @param {string} name - name to set to
   */
  set selectedName(name) {
    this.data.set('value', name || '');
    this.inputTarget.value = name;
  }

  connect() {
    super.connect();

    this.inputTarget.value = this.selectedName;
  }

  onBlur() {
    super.onBlur();
    if (!this.inputTarget.value) {
      this.clearSelection();
    } else {
      this.inputTarget.value = this.selectedName;
    }
  }

  /** Clear the currently selected item */
  clearSelection() {
    this.selectedId = null;
    this.selectedName = null;
  }

  /**
   * Click handler for selecting an option from the results
   *
   * @param {event} event - event from the selected result, which stores the
   *   fields as data attributes.
   */
  select(event) {
    this.selectedId = event.currentTarget.dataset.id;
    this.selectedName = event.currentTarget.innerText;
    this.closeList();
    this.inputTarget.blur();
  }
}
