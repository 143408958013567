import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['password', 'passwordConfirmation', 'errorLabel', 'submit'];

  validatePassword() {
    if(this.passwordTarget.value !== this.passwordConfirmationTarget.value) {
      this.invalidPassword('Passwords do not match')
    }
    else if(this.passwordTarget.value.length < 6) {
      this.invalidPassword('Password is too short')
    }
    else {
      this.validPassword()
    }
  }

  invalidPassword(errorMessage) {
    this.submitTarget.disabled = true
    this.errorLabelTarget.innerHTML = errorMessage;
  }

  validPassword() {
    this.submitTarget.disabled = false
    this.errorLabelTarget.innerHTML = '';
  }
}
