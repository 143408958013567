const requestHeaders = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
}

class ApiRequest {
  createClaim(data) {
    return fetch(
      '/timesheets/claims',
      {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: requestHeaders,
      },
    )
      .then(response => response.json());
  }

  updateClaim(data) {
    return fetch(
      `/timesheets/claims/${data.claim.id}`,
      {
        method: 'PUT',
        body: JSON.stringify(data),
        credentials: 'same-origin',
        headers: requestHeaders,
      },
    )
      .then(response => response.json());
  }

  queryTimesheets(opt) {
    let params = opt.params || {};
    let paramString = Object.entries(params).map(x => x[0] + '=' + x[1]).join('&');

    return fetch(
      `/query/${opt.path}?${paramString}`,
      {
        method: 'GET',
        credentials: 'same-origin',
        headers: requestHeaders,
      }
    )
      .then(response => response.json());
  }

  updateClaimStatus(opt) {
    return fetch(
      opt.url,
      {
        method: 'PUT',
        credentials: 'same-origin',
        body: JSON.stringify(opt.body),
        headers: requestHeaders,
      },
    )
      .then(response => response.json());
  }
}

export default ApiRequest;
