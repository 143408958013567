import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  toggleList(event) {
    const lists = document.getElementsByName('lists')
    const down_arrows = document.getElementsByName('down-arrows')
    const up_arrows = document.getElementsByName('up-arrows')

    const list = document.getElementById(`list-${event.target.id}`)
    list.classList.toggle('hide')
    const down_arrow = document.getElementById(`down-arrow-${event.target.id}`)
    down_arrow.classList.toggle('hide')
    const up_arrow = document.getElementById(`up-arrow-${event.target.id}`)
    up_arrow.classList.toggle('hide')
  }
}
