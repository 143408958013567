class ClaimedAdditionalExpense {
  constructor(claimedAdditionalExpense) {
    this.setAttributes(claimedAdditionalExpense);
  }

  setAttributes(claimedAdditionalExpense) {
    this.id = claimedAdditionalExpense.id;
    this.additionalExpenseId = claimedAdditionalExpense.additional_expense_id;
    this.additionalExpense = claimedAdditionalExpense.additional_expense;
    this.claimedActivityId = claimedAdditionalExpense.claimed_activity_id;
    this.amountDue = claimedAdditionalExpense.amount_due;
    this.value = claimedAdditionalExpense.value;
    this._destroy = claimedAdditionalExpense.destroy
  }

  attributes() {
    if (this.additionalExpenseId === null) {
      return {};
    }

    return {
      id: this.id,
      additional_expense_id: this.additionalExpenseId,
      claimed_activity_id: this.claimedActivityId,
      value: this.value,
      _destroy: this['_destroy'],
    };
  }
}

export default ClaimedAdditionalExpense;