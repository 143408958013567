const requestHeaders = {
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
};

import { serialize } from '../utilities';
import {
        drawYOrigin, addNewGraph, addYvalueLines, addXmarks, addXlabels,
        highestYValue, initialXValue, horizontalDistanceBetweenDataPoints,
        yValue, addYlabels, addLegend, colors, addToolTip, anyNegativeYvalues,
        highestNegValue, isDataAvailable, unitValue, addBaselineLegend,
        getBaselineValues,  bottomValue, topValue
       } from './graph_utilities';

function drawMultiSeriesGraph(start_date, end_date, companyId, indicatorIds){
  getMultiSeriesData(start_date, end_date, companyId, indicatorIds).then(data => {
    const indicatorsPlotted = getSeriesStatuses(indicatorIds); //Check if an indicator has been plotted or not

    document.getElementById('svg--').remove(); // remove current graph
    const newSvgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg'); // New svg element
    const yOriginSvgPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const indicatorId = '';

    newSvgGraph(newSvgElement); // create new svg element
    drawYOrigin(newSvgElement, yOriginSvgPathElement, data); // add horizontal zero line
    addYvalueLines(newSvgElement, data); // add horizontal y value lines
    addYlabels(newSvgElement, data); // add y-axis values
    addXmarks(newSvgElement, data, 0, 0); // add vertical marks on the zero line for the current period (0) and with no previous data sets (0)
    addXlabels(newSvgElement, data, 0, 0);  // add vertical labels on the zero line for the current period (0) and with no previous data sets (0)

    for (var i = 0; i < data.length; i++) {
      //Add legend
      addLegend(newSvgElement, data, i);
    }

    if (isDataAvailable(data).length) {
      plotYbars(newSvgElement, data); // add vertical bars to represent an indicator value
      addBaselines(newSvgElement, data); // add baselines
    } else {
      var waterMark = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      waterMark.setAttribute('color', '#E0E0E157');
      waterMark.setAttribute('font-size', '20');
      waterMark.setAttribute('font-weight', '400');
      waterMark.setAttribute('x', '67');
      waterMark.setAttribute('y', '40');
      waterMark.innerHTML = 'No data';

      newSvgElement.appendChild(waterMark);
    }
    // Add the new graph to the DOM
    addNewGraph(indicatorId, newSvgElement);
    addBaselineLegend(newSvgElement); //Add baseline legend
    concealHiddenSeries(indicatorsPlotted); //Hide previously unchecked/hidden series before plotting
  });
}

function plotYbars(newSvgElement, dataPoints) {
  var period;
  for (var i = 0; i < dataPoints.length; i++) {
    // dataPoints - all data sets; dataPoints[i] - data set for indicator [i]
    // dataPoints[i]['data'] - data for indicator [i];
    // dataPoints[i]['data'][0] - First period or year data for indicator [i]
    // dataPoints[i]['data'][0][1] - data points for the indicator [i] in the first period or year
    var dataSetsInFirstPeriod = dataPoints[i]['data'][0][1].length; //data set in the first period or year ()
    var numberOfNextPeriodDataSets = 12 - dataSetsInFirstPeriod;
    const dateRange = document.getElementById('charts_date_range');
    const selectedOption = dateRange.options[dateRange.selectedIndex].innerHTML;

    numberOfNextPeriodDataSets = selectedOption == 'Current Year' ? 0 : numberOfNextPeriodDataSets;

    // Plotting the first period/year on the graph
    for (var j = 0; j < dataSetsInFirstPeriod; j++) {
      period = 0; //first year
      var yBarsPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      var value = yValue(dataPoints[i]['data'][0][1][j][1]);
      const absBottomValue = Math.abs(bottomValue(dataPoints));
      var highestAbsoluteYvalue = topValue(dataPoints) > absBottomValue ? topValue(dataPoints) : absBottomValue;

      yBarsPath.setAttribute('class', `bar-graph__data series--${i}`);
      yBarsPath.setAttribute('stroke', `${colors[i]}`);
      yBarsPath.setAttribute('cursor', 'pointer');
      yBarsPath.setAttribute('stroke-width', '1.5');
      yBarsPath.setAttribute('id', `${i}${period}${j}`);
      yBarsPath.setAttribute('data-action','mouseover->dashboard-restyle--submit-form#displayData mouseout->dashboard-restyle--submit-form#hideData');

      if (anyNegativeYvalues(dataPoints)) {
        yBarsPath.setAttribute(
          'd',
          `M${initialXValue(dataPoints) + 3.75 + (1.75*i) + (horizontalDistanceBetweenDataPoints()*j)} 35 v${-20*value/highestAbsoluteYvalue}`
        );
        newSvgElement.appendChild(yBarsPath);
      } else {
        yBarsPath.setAttribute(
          'd',
          `M${initialXValue(dataPoints) + 3.75 + (1.75*i) + (horizontalDistanceBetweenDataPoints()*j)} 55 v${-40*value/highestAbsoluteYvalue}`
        );
        newSvgElement.appendChild(yBarsPath);
      }
    }

    // adding graph attributes for period 2 or year 2
    if (numberOfNextPeriodDataSets != 0 && dataPoints[i]['data'].length > 1) {
      var period = dataPoints[i]['data'].length == 1 ? 0 : 1;
      for (var k = 0; k < dataPoints[i]['data'][period][1].length; k++) {
        if (k >= numberOfNextPeriodDataSets) { continue; }
        var value = yValue(dataPoints[i]['data'][period][1][k][1]);
        var highestAbsoluteYvalue = value > 0 ? topValue(dataPoints) : bottomValue(dataPoints)*(-1);

        var yBarsPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        yBarsPath.setAttribute('class', `bar-graph__data series--${i}`);
        yBarsPath.setAttribute('stroke', `${colors[i]}`);
        yBarsPath.setAttribute('cursor', 'pointer');
        yBarsPath.setAttribute('stroke-width', '1.5');
        yBarsPath.setAttribute('id', `${i}${period}${k}`);
        yBarsPath.setAttribute('data-action','mouseover->dashboard-restyle--submit-form#displayData mouseout->dashboard-restyle--submit-form#hideData');

        if (i == 0 && k == 0 && period != 0) {
          addXmarks(newSvgElement, dataPoints, period, dataSetsInFirstPeriod);
          addXlabels(newSvgElement, dataPoints, period, dataSetsInFirstPeriod);
        }
        dataSetsInFirstPeriod = period == 0 ? 0 : dataSetsInFirstPeriod;
        if (anyNegativeYvalues(dataPoints)) {
          yBarsPath.setAttribute(
            'd',
            `M${initialXValue(dataPoints) + 3.75 + (1.75*i) + (horizontalDistanceBetweenDataPoints()*(k+dataSetsInFirstPeriod))} 35 v${-20*value/highestAbsoluteYvalue}`
          );
        }  else {

          yBarsPath.setAttribute(
            'd',
            `M${initialXValue(dataPoints) + 3.75 + (1.75*i) + (horizontalDistanceBetweenDataPoints()*(k+dataSetsInFirstPeriod))} 55 v${-40*value/highestAbsoluteYvalue}`
          );
        }
        newSvgElement.appendChild(yBarsPath);
      }
    }
  }

  for (var i = 0; i < dataPoints.length; i++) {
    for (var j = 0; j < dataSetsInFirstPeriod; j++) {
      period = 0; //first year
      addToolTip(newSvgElement, dataPoints, i, j, period); // add tooltip to display graph information
    }

    if (numberOfNextPeriodDataSets != 0 && dataPoints[i]['data'][1]) {
      period = 1; //second year data

      for (var k = 0; k < dataPoints[i]['data'][period][1].length; k++) {
        addToolTip(newSvgElement, dataPoints, i, k, period); // add tooltip to display graph information
      }
    }
  }
}

function newSvgGraph(newSvgElement) {
  newSvgElement.setAttribute('id', `svg--`);
  newSvgElement.setAttribute('viewBox', '0 3 215 75');
}

function getSeriesStatuses(indicatorIds) {
  var indicatorIds = indicatorIds.split(' ');
  const seriesElements = [];
  const seriesStatuses = new Map();

  for (var i = 0; i < indicatorIds.length; i++) {
    seriesElements.push(document.getElementsByClassName(`series--${i}`)[0]);
  }

  for (var i = 0; i < seriesElements.length; i++) {
    seriesStatuses.set(`series--${i}`,`${[...seriesElements[i].classList].includes('hide')}`)
  }
  return seriesStatuses;
}

function returnCheckedSeries(series) {
  var series = Array.from(series, (([key, value]) => value));
  var checkedSeries = [];

  for (var i = 0; i < series.length; i++) {
    if (series[i] == 'false') {
      checkedSeries.push(series[i]);
    }
  }
  return checkedSeries;
}

function hideAllBaselines() {
  for (var i = 0; i < 4; i++) {
    const allBaselines = document.getElementsByClassName(`baseline--${i}`);

    for (var j = 0; j < allBaselines.length; j++) {
      allBaselines[j].classList.add('hide');
    }
  }
}

function displayBaseline(baselines) {
  for (var i = 0; i < baselines.length; i++) {
    baselines[i].classList.remove('hide');
  }
}

function concealHiddenSeries(indicatorsPlotted) {
  hideAllBaselines();

  var checkedSeries = returnCheckedSeries(indicatorsPlotted);

  for (var [key, value] of indicatorsPlotted) {
    const indicatorSeries = document.getElementsByClassName(`${key}`);
    const baselines = document.getElementsByClassName(`baseline--${key.split('--')[1]}`);

    if (value == 'false') {
      if (checkedSeries.length == 1 ) {
        displayBaseline(baselines);
      }
      continue;
    }

    for (var i = 0; i < indicatorSeries.length; i++) {
      indicatorSeries[i].classList.add('hide');
    }
  }
}

function addBaselines(newSvgElement, dataPoints) {
  const currency = document.getElementsByName('currency')[0].content;

  var baselineValues = dataPoints.map(getBaselineValues);
  const baselineUnit = dataPoints[0]['indicator']['unit'];
  var highestAbsoluteYvalue = highestYValue(dataPoints) > highestNegValue(dataPoints) ? highestYValue(dataPoints) : highestNegValue(dataPoints);

  for (var i = 0; i < baselineValues.length; i++) {
    var baselineValIndicator = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    var baselinePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    var baselineText = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    baselineValIndicator.setAttribute('stroke', '#EC7405');
    baselineValIndicator.setAttribute('fill', '#EC7405');
    baselineValIndicator.setAttribute('cx', initialXValue(dataPoints));
    baselineValIndicator.setAttribute('r', '0.20');
    baselineValIndicator.setAttribute('cursor', 'pointer');

    baselinePath.setAttribute('stroke', '#EC7405');
    baselinePath.setAttribute('stroke-width', '0.4');

    baselineText.setAttribute('x', initialXValue(dataPoints));
    baselineText.setAttribute('text-anchor', 'start');
    baselineText.setAttribute('font-size', '3');
    baselineText.setAttribute('line-height', '10');
    baselineText.setAttribute('color', '#63666A');
    baselineText.setAttribute('font-weight', '400');
    baselineText.setAttribute('class', 'hide');

    if (baselineValues[i] && !anyNegativeYvalues(dataPoints)) {
      baselineValIndicator.setAttribute('cy', `${55 - (40.0*baselineValues[i]/topValue(dataPoints))}`);
      baselineValIndicator.setAttribute('data-action', 'mouseover->dashboard-restyle--data-series#toggleBaseline mouseout->dashboard-restyle--data-series#toggleBaseline');
      baselineValIndicator.setAttribute('id', `${dataPoints[i]['indicator']['id']}`);
      baselineValIndicator.setAttribute('class', `baseline--${i}`);

      baselinePath.setAttribute('class', `baseline--${i}`);
      baselinePath.setAttribute('d', `M${initialXValue(dataPoints)} ${55 - (40.0*baselineValues[i]/topValue(dataPoints))} h190`);

      baselineText.setAttribute('y', `${53 - (40.0*baselineValues[i]/topValue(dataPoints))}`);
      baselineText.setAttribute('id', `baseline--${dataPoints[i]['indicator']['id']}`);
      baselineText.innerHTML = unitValue(currency, baselineUnit, baselineValues[i]);
    }

    if (baselineValues[i] && anyNegativeYvalues(dataPoints)) {
      baselineValIndicator.setAttribute('cy', `${baselineValues[i] > 0 ? 35 - (20.0*baselineValues[i]/topValue(dataPoints)) : 35 - (20*baselineValues[i]/bottomValue(dataPoints))}`);
      baselineValIndicator.setAttribute('data-action', 'mouseover->dashboard-restyle--data-series#toggleBaseline mouseout->dashboard-restyle--data-series#toggleBaseline');
      baselineValIndicator.setAttribute('id', `${dataPoints[i]['indicator']['id']}`);
      baselineValIndicator.setAttribute('class', `baseline--${i}`);

      baselinePath.setAttribute('class', `baseline--${i}`);
      baselinePath.setAttribute('d', `M${initialXValue(dataPoints)} ${baselineValues[i] > 0 ? 35 - (20.0*baselineValues[i]/(topValue(dataPoints))) : (35 - (20*baselineValues[i]/bottomValue(dataPoints)))} h190`);

      baselineText.setAttribute('y', `${baselineValues[i] > 0 ? 33 - (20.0*baselineValues[i]/topValue(dataPoints)) : (33 - (20*baselineValues[i]/bottomValue(dataPoints)))}`);
      baselineText.setAttribute('id', `baseline--${dataPoints[i]['indicator']['id']}`);
      baselineText.innerHTML = unitValue(currency, baselineUnit, baselineValues[i]);
    }

    newSvgElement.appendChild(baselineValIndicator);
    newSvgElement.appendChild(baselinePath);
    newSvgElement.appendChild(baselineText);
  }
}

function getMultiSeriesData(start_date, end_date, companyId, indicatorIds) {
  const charts_params = {
                          'charts':
                          {
                            'company_id': companyId,
                            'indicator_ids': indicatorIds,
                            'date_range': { 'start_date': start_date, 'end_date': end_date },
                          }
                        };

  return fetch(
    `/charts/multi_series_indicator_values?${serialize(charts_params)}`,
    {
      method: 'GET',
      credentials: 'same-origin',
      headers: requestHeaders,
    },
  ).then(response => response.json());
}

export { drawMultiSeriesGraph }
