function strokeWidth(dataPoints) {
  if (numberOfDataPoints(dataPoints) > 26) {
    return 1;
  } else if (numberOfDataPoints(dataPoints) > 20) {
    return 1.5;
  } else {
    return 2;
  }
}

function numberOfDataPoints(dataPoints) {
  return dataPoints.length;
}

function xLabelFontSize(dataPoints) {
  if (numberOfDataPoints(dataPoints) > 26) {
    return 2.3;
  } else if (numberOfDataPoints(dataPoints) > 20) {
    return 2.5;
  } else {
    return 3;
  }
}

function xLabelRotation(dataPoints) {
  if (numberOfDataPoints(dataPoints) < 8) {
    return 0;
  } else {
    return -45;
  }
}

function yLabelTransformOrigin(dataPoints) {
  if (numberOfDataPoints(dataPoints) > 26) {
    return 70;
  } else {
    return 74;
  }
}

export { strokeWidth, numberOfDataPoints, xLabelFontSize, xLabelRotation, yLabelTransformOrigin };
