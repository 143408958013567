import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
import Inputmask from 'inputmask/dist/inputmask/inputmask.numeric.extensions';

export default class extends Controller {
  static targets = ['editable']
  static values = {
    message: String,
    type: String,
    previous: Number
  }

  makeEditable(event) {
    event.preventDefault();

    let value = this.editableTarget.innerHTML.split('</span>')[1].split('<span')[0]
    let preSpan = this.editableTarget.children[0].outerHTML
    // let dotSpan = this.editableTarget.children[1].outerHTML
    let postSpan = this.editableTarget.children[1].outerHTML

    this.previousValue = value;

    let editor = document.createElement("input");
    editor.value = value
    editor.classList.add("field-editable");

    let selection = window.getSelection();
    editor.setSelectionRange(selection.anchorOffset, selection.anchorOffset);

    this.maskInputField(editor);

    this.editableTarget.insertAdjacentElement('afterend', editor);
    this.editableTarget.classList.add("editable--hidden");

    editor.focus();

    // Listens for a keypress event to save edit
    editor.addEventListener('keypress', function(event) {
      let key = event.which || event.keyCode;

      if (key === 13) { // 13 is enter
        this.editableTarget.innerHTML = `${preSpan}${editor.value}${postSpan}`;

        this.editableTarget.classList.remove("editable--hidden");
        editor.remove();
      }
    }.bind(this));

    // Listens for a focusout event to close editor if clicked outside
    editor.addEventListener('focusout', function(event) {
      var fieldType = this.editableTarget.getAttribute('field-type');
      this.editableTarget.innerHTML = `${preSpan}${editor.value}${postSpan}`;

      if (isNaN(editor.value)) {
        alert('Please enter a number')
        return;
      }

      if(this.previousValue != editor.value) {
        this.runUpdates(fieldType, editor);
      }

      editor.remove()

      this.editableTarget.classList.remove("editable--hidden");

    }.bind(this));
  }

  runUpdates(fieldType, editor) {
    if (fieldType == 'indicator') {
      this.updateIndicator(
        this.editableTarget.getAttribute('indicator-id'),
        this.editableTarget.getAttribute('month-id'),
        parseFloat(editor.value)
      );
    }
    else if (fieldType === 'baseline') {
      this.updateBaseline(
        this.editableTarget.getAttribute('baseline-id'),
        parseFloat(editor.value)
      );
    }
  }

  updateIndicator(id, month, value) {
    var company_id = document.getElementById('company_id').getAttribute('company-id');

    let id_string = `indicator_value[indicator_id]=${id}`
    let value_string = `indicator_value[indicator_value]=${value}`
    let month_string = `indicator_value[referring_to]=${month}`
    let company_string = `indicator_value[company_id]=${company_id}`

    this.makeUpdateCall(`${id_string}&${value_string}&${month_string}&${company_string}`);
  }

  updateBaseline(id, value) {
    this.makeUpdateCall(`baseline[value]=${value}&baseline[id]=${id}`);
  }

  makeUpdateCall(data) {
    var company_id = document.getElementById('company_id').getAttribute('company-id');

    Rails.ajax({
      type: 'PUT',
      url: `/companies/${company_id}/indicator_values`,
      data: data,
      success: this.addMessage({ message: "The indicator value has been updated. Please refresh your page for any affected values to be updated", type: "notice" })
    });
  }

  addMessage(content) {
    const flashContainer = document.getElementsByClassName("flash-messages")[0];

    if (!flashContainer) return;

    const template = flashContainer.querySelector("[data-template]");
    const node = template.content.firstElementChild.cloneNode(true);
    node.classList.add("flash-message--"+content.type);
    node.querySelector("[data-value]").innerText = content.message;

    flashContainer.append(node);

    this.flashMessageTimeout = window.setTimeout(() => {
      node.remove();
    }, 10000);
  }

  maskInputField(editor){
    Inputmask('decimal', {
      groupSeparator: ' ',
      autoGroup: true,
      rightAlign: false,
      autoUnmask: true,
      removeMaskOnSubmit: true,
    }).mask(editor);
  }

  disconnect(){
    window.clearTimeout(this.flashMessageTimeout)
  }
}
