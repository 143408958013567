import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    let button = document.getElementById("submit_button");

    button.addEventListener("click", function() {
      Turbolinks.visit(window.location)
    });
  }
}
