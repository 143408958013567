/**
 * Replace the target's content with the given nodes
 * @param {HTMLElement} target the target whose content is to be replaced
 * @param {...Node} nodes nodes to replace content with
 */
export function setContent(target, ...nodes) {
  target.innerHTML = '';
  nodes.forEach(node => target.append(node));
}

/**
 * Replace the target's content with the given template
 * @param {HTMLElement} target the target whose content is to be replaced
 * @param {HTMLTemplateElement} template the template to replace content with
 */
export function setContentWithTemplate(target, template) {
  let node = template.content.cloneNode(true);
  setContent(target, node);
}
