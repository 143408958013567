import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stack', 'info', 'tail', 'tailEnd']

  setActive(event) {
    event.preventDefault();
    this.removeDefault();
    this.resetColor();
    let id = parseInt(event.target.dataset.stackId, 10);

    this.stackTargets[id - 1].classList.toggle('bmc-active');

    this.infoTarget.querySelectorAll('.bmc-intro__info-data')[id].classList.remove('hide');
  }

  setNextActive(event) {
    event.preventDefault();
    this.removeDefault();
    this.resetColor();
    let id = parseInt(event.target.dataset.buttonId, 10);

    if (id === 0) {
      this.stackTargets[4].classList.toggle('bmc-active');
      this.stackTargets[4].classList.remove('bmc-intro__unclickable');
      this.infoTarget.querySelectorAll('.bmc-intro__info-data')[5].classList.remove('hide');
    }
    else {
      this.stackTargets[id - 2].classList.toggle('bmc-active');
      this.stackTargets[id - 2].classList.remove('bmc-intro__unclickable');
      this.infoTarget.querySelectorAll('.bmc-intro__info-data')[id - 1].classList.remove('hide');
    }

  }

  removeDefault() {
    [...document.querySelectorAll('.bmc-intro__stack-layer')].forEach((el, i) => {
      el.classList.remove('layer-default');
    });
  }

  resetColor() {
    [...document.querySelectorAll('.bmc-intro__stack-layer')].forEach((el, i) => {
      el.classList.remove('bmc-active');
    });
    [...document.querySelectorAll('.bmc-intro__info-data')].forEach((el, i) => {
      el.classList.add('hide');
    });
    [...document.querySelectorAll('.bmc-intro__info-tail')].forEach((el, i) => {
      el.classList.remove('bmc-active');
    });
  }
}



