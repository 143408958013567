import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['characterCount', 'textArea', 'newlineCount']

  connect(){
    this.numberLimit = 300;
    this.newlineLimit = 10;

    const usedCharacters = this.textAreaTarget.value.length
    const usedNewlineChar = this.textAreaTarget.value.split('\n').length - 1

    this.characterCountTarget.innerHTML = `Character limit: ${this.numberLimit - usedCharacters}/${this.numberLimit}`
    this.newlineCountTarget.innerHTML = `Line limit: ${this.newlineLimit - usedNewlineChar}/${this.newlineLimit}`
  }

  updateCount(event) {
    const usedCharacters = this.textAreaTarget.value.length
    const usedNewlineChar = this.textAreaTarget.value.split('\n').length - 1

    this.characterCountTarget.innerHTML = `Character limit: ${this.numberLimit - usedCharacters}/${this.numberLimit}`
    this.newlineCountTarget.innerHTML = `Line limit: ${this.newlineLimit - usedNewlineChar}/${this.newlineLimit}`

    if (usedCharacters >= this.numberLimit) {
      this.textAreaTarget.value = this.textAreaTarget.value.substring(0, this.numberLimit - 1)
    }

    if (usedNewlineChar >= this.newlineLimit) {
      this.textAreaTarget.maxLength = usedNewlineChar
    } else {
      this.textAreaTarget.maxLength = this.numberLimit
    }
  }

}
