/**
 * Extracts a query string's key-value pairs into an object
 * @param {string} query a query string (can include the leading `?`)
 * @returns {Object} the query strings keys and values in object form
 */
export function queryAsObject(query) {
  if (!query) return {};

  return query
    .replace(/^\?/, '')
    .split('&')
    .map(kv => kv.split('='))
    .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
}

/**
 * Creates a query string from the key-value pairs in the object. Result includes the leading `?`;
 * @param {Objecty} obj an object to be converted to a query string
 * @returns {string} a query string resulting from the object
 */
export function objectAsQuery(obj) {
  let pairs = [];
  for (let k in obj) {
    pairs.push(`${k}=${obj[k]}`);
  }
  if (pairs.length === 0) return '';
  return `?${pairs.join('&')}`;
}

/**
 * Adds a key-value pair to the current URL's query string without reloading, using the history API
 * @param {string} key the property's key
 * @param {string} value the property's value
 */
export function setUrlProperty(key, value) {
  let query = queryAsObject(location.search);
  if (value) {
    query[key] = value;
  } else {
    delete query[key];
  }
  setQueryString(objectAsQuery(query));
}

/**
 * Sets the query string in the current URL without reload, using the history API
 * @param {string} queryString a query string with the leading `?`
 */
export function setQueryString(queryString) {
  let url = `${location.origin}${location.pathname}${queryString}${location.hash}`;
  history.replaceState(null, '', url);
}

/**
 * Sets the current URL's query string with values from a form.
 * @param {HTMLFormElement} form form from which to extract query
 */
export function setQueryFromForm(form) {
  setQueryString(queryFromForm(form));
}

/**
 * Generates a query string from a form.
 * @param {HTMLFormElement} form form from which to extract query
 * @returns {string} query string generated from the form's values. Includes the leading `?`
 */
export function queryFromForm(form) {
  return `?${new URLSearchParams(new FormData(form)).toString()}`;
}
