import { Controller } from 'stimulus';

export default class extends Controller {

  toggleSetup(event) {
    event.preventDefault();
    let indicator_id = event.target.id;

    var addbaselineSetupBtn = document.getElementsByClassName(`add-baseline-setup--btn--${indicator_id}`)[0];
    var baselineSetupForm = document.getElementsByClassName(`baseline-setup--form--${indicator_id}`)[0];
    var baselineSetupInput = document.getElementsByClassName(`baseline-setup--input--${indicator_id}`);
    var closeBaselineSetupBtn = document.getElementsByClassName(`close-baseline-setup--btn--${indicator_id}`)[0];
    var canceBaselineSetupBtn = document.getElementsByClassName(`cancel-baseline-setup--btn--${indicator_id}`)[0];
    var graphBaselineForm = document.getElementsByClassName(`indicator__graph--baseline-form--${indicator_id}`)[0];
    var graphBaselineBtn = document.getElementsByClassName(`indicator__graph--baseline-btn--${indicator_id}`)[0];
    var emptyStateGraph = document.getElementsByClassName(`indicator__empty-state--${indicator_id}`)[0];
    var graphBaselineHeaderText = document.getElementsByClassName(`indicator__graph--baseline-header_text--${indicator_id}`)[0];
    var graphBaselineBodyText = document.getElementsByClassName(`indicator__graph--baseline-body_text--${indicator_id}`)[0];
    var graphBaselineDescText = document.getElementsByClassName(`indicator__graph--baseline-desc_text--${indicator_id}`)[0];

    if (baselineSetupInput[0]) {
      baselineSetupInput[0].removeAttribute('disabled'); //component call in the cluster header data capture
    }

    if (baselineSetupInput[1]) {
      baselineSetupInput[1].removeAttribute('disabled'); //component call in the empty graph state
    }

    if (graphBaselineForm) {
      graphBaselineForm.classList.toggle("flex-display");
      graphBaselineForm.classList.toggle("hide");
    }

    if (graphBaselineHeaderText) {
      graphBaselineHeaderText.classList.toggle("hide");

    }

    if (graphBaselineBodyText) {
      graphBaselineBodyText.classList.toggle("hide");
    }

    if (graphBaselineBtn) {
      graphBaselineBtn.classList.toggle("hide");
    }

    if (graphBaselineDescText) {
      graphBaselineDescText.classList.toggle("hide");
    }

    if (emptyStateGraph) {
      emptyStateGraph.classList.toggle("hide");
    }

    if (baselineSetupForm) {
      baselineSetupForm.classList.toggle("hide");
    }

    addbaselineSetupBtn.classList.toggle("flex-display");
    addbaselineSetupBtn.classList.toggle("hide");

    closeBaselineSetupBtn.classList.toggle("flex-display");
    closeBaselineSetupBtn.classList.toggle("hide");
    canceBaselineSetupBtn.classList.toggle("flex-display");
    canceBaselineSetupBtn.classList.toggle("hide");
  }
}
