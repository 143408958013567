import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [""];

  makeActive() {

    [...document.querySelectorAll('.bmc-insights__block')].forEach((el, i) => {
      el.classList.add('hide')
    });
    [...document.querySelectorAll('.bmc-insights__active-block__content')].forEach((el, i) => {
      el.classList.add('hide');
    });

    document.getElementsByClassName('bmc-insights__active-block')[0].classList.add('visible')
    document.getElementById(`active-${event.target.id}`).classList.remove('hide')
  }

  deactivate() {

    [...document.querySelectorAll('.bmc-insights__block')].forEach((el, i) => {
      el.classList.remove('hide')
    });
    [...document.querySelectorAll('.bmc-insights__active-block__content')].forEach((el, i) => {
      el.classList.add('hide');
    });

    document.getElementsByClassName('bmc-insights__active-block')[0].classList.remove('visible')
  }
}
