import { Controller } from 'stimulus';

export default class extends Controller {

  toggle(event) {
    event.preventDefault();
    let indicator_id = event.target.id;

    //card graph
    var graphDetails = document.getElementById(`graph-details-${indicator_id}`);
    graphDetails.classList.toggle("hide");

    var graphPlot = document.getElementById(`graph-plot-${indicator_id}`);
    graphPlot.classList.toggle("hide");

    var graphStatus = document.getElementById(`graph-status-${indicator_id}`);
    graphStatus.classList.toggle("hide");

    // card messages
    var indicatorInfo = document.getElementById(`graph-summary-${indicator_id}`);
    indicatorInfo.classList.toggle("hide");
  }
}
