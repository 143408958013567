import { Controller } from 'stimulus';

const EVENTS = ['change', 'input', 'cut', 'paste', 'drop', 'keydown', 'resize'];

/**
 * @module AutoresizeController
 * @description Automatically resizes a textarea element based on content.
 *
 */
export default class extends Controller {
  connect() {
    this.resize = this.resize.bind(this);

    EVENTS.forEach(eventName => {
      this.element.addEventListener(eventName, this.resize, false);
    });
    window.addEventListener('resize', this.resize, false);
    this.resize();
  }

  disconnect() {
    EVENTS.forEach(eventName => {
      this.element.removeEventListener(eventName, this.resize);
    });
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    setImmediate(() => {
      this.element.style.height = 'auto';
      this.element.style.height = this.element.scrollHeight + 'px';
      this.element.blur();
      this.element.focus();
    });
  }
}
