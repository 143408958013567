class UserClaim {
  constructor(userClaim) {
    this.setAttributes(userClaim);
  }

  setAttributes(userClaim) {
    this.id = userClaim.id;
    this.userId = userClaim.user_id;
    this.claimId = userClaim.claim_id;
  }

  attributes() {
    const attributes = {
      id: this.id,
      user_id: this.userId,
      claim_id: this.claimId
    }

    return attributes;
  }
}

export default UserClaim;
