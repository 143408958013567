import ClaimedAdditionalExpense from './claimedAdditionalExpense'

class ClaimedActivity {
  constructor(claimedActivity) {
    this.setAttributes(claimedActivity);
  }

  setAttributes(claimedActivity) {
    this.id = claimedActivity.id;
    this.claimId = claimedActivity.claim_id;
    this.activityId = claimedActivity.activity_id;
    this.accreditationLevel = claimedActivity.accreditation_level;
    this.activityStatusId = claimedActivity.activity_status_id;
    this.amountDue = claimedActivity.amount_due;
    this.accreditationLevelId = claimedActivity.accreditation_level_id;
    this.outOfTown = claimedActivity.out_of_town;
    this.sessionNumber = claimedActivity.session_number;
    this.referringTo = claimedActivity.referring_to;
    this.claimedAdditionalExpenses = claimedActivity
      .claimed_additional_expenses.map(cAE => new ClaimedAdditionalExpense(cAE));
  }

  attributes() {
    const attributes = {
      id: this.id,
      claim_id: this.claimId,
      activity_id: this.activityId,
      accreditation_level_id: this.accreditationLevelId,
      activity_status_id: this.activityStatusId,
      out_of_town: (this.outOfTown === 'true' || this.outOfTown === true),
      session_number: this.sessionNumber,
      referring_to: this.referringTo,
      _destroy: this['_destroy'],
      claimed_additional_expenses_attributes: this.claimedAdditionalExpenses
        .map(cAE => cAE.attributes()),
    };

    return attributes;
  }
}

export default ClaimedActivity;
