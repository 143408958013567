import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['body', 'header']

  toggle() {
    this.headerTarget.classList.toggle("active");
    this.bodyTarget.style.display = this.bodyTarget.style.display === 'block' ? 'none' : 'block';
  }
}
