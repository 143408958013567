import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ["shortText", "infoIcon", "fullText"]

  toggle() {
    this.infoIconTarget.classList.toggle("hide");
    this.shortTextTarget.classList.toggle("hide");
    this.fullTextTarget.classList.toggle("hide");
  }
}
