import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['infoTooltip']
  connect() {
    this.infoTooltipTarget.addEventListener('click', this.handleInfoTooltipClick)
  }
  disconnect(){
    this.infoTooltipTarget.removeEventListener('click', this.handleInfoTooltipClick)
  }
  handleInfoTooltipClick(event){
    event.stopPropagation()
  }

  showRelated(event) {
    const actions = document.getElementsByClassName('flags__action')
    const components = document.getElementsByClassName('flags__component')

    for (var i = 0; i < actions.length; i++) {
      actions[i].classList.add('hide')
    }

    for (var i = 0; i < components.length; i++) {
      components[i].classList.add('hide')
    }

    const action = document.getElementsByName(`action-${event.target.getAttribute('name')}`)[0]
    action.classList.remove('hide')

    const component = document.getElementsByName(`component-${event.target.getAttribute('name')}`)[0]
    component.classList.remove('hide')
  }

  showFlagScore(event) {
    const actions = document.getElementsByClassName('flags__action')
    const components = document.getElementsByClassName('flags__component')

    for (var i = 0; i < actions.length; i++) {
      actions[i].classList.add('hide')
    }

    for (var i = 0; i < components.length; i++) {
      components[i].classList.add('hide')
    }

    const action = document.getElementsByName(`action-trend`)[0]
    action.classList.remove('hide')
  }

  displayDescription() {
    var className = 'flag-summary__tooltip--'+event.target.parentElement.id;
    this.toggleVisibility(className, 'visible');
  }

  hideDescription() {
    var className = 'flag-summary__tooltip--'+event.target.parentElement.id;
    this.toggleVisibility(className, 'hidden');
  }

  toggleVisibility(className, attribute) {
    var description = document.getElementsByClassName(className);
    if (description[0] !== undefined) description[0].style.visibility = attribute;
  }
}
