import SearchBase from './search_base';

/**
 * @module MultiselectController
 * @description Controller for `MultiselectComponent`
 *
 */
export default class extends SearchBase {
  static targets = [...SearchBase.targets, 'chipTemplate', 'chip', 'chipContainer'];

  get selectedIds() {
    return this.chipTargets.map(chip => chip.querySelector('input').value);
  }

  get max() {
    if (!this.data.has('max')) return Infinity;
    return parseInt(this.data.get('max'), 10);
  }

  /**
   * Callback used by `SearchBase` when displaying result.
   * Makes sure results don't include already selected items.
   *
   * @param {Object} item - a single result item to be filtered
   * @returns {boolean} - whether or not the item will show in the results.
   */
  filter(item) {
    return !this.selectedIds.includes(item[this.key]);
  }

  /**
   * Click handler for selecting an option from the results
   *
   * @param {event} event - event from the selected result, which stores the
   *   fields as data attributes.
   */
  select(event) {
    this.closeList();
    this.addChip(event.currentTarget.dataset.id, event.currentTarget.innerText.trim());
    this.inputTarget.value = '';
    setTimeout(() => this.inputTarget.focus(), 0);
  }

  addChip(id, name) {
    let chip = this.chipTemplateTarget.content.cloneNode(true);
    chip.querySelector('label').innerText = name;
    chip.querySelector('input').value = id;
    this.chipContainerTarget.append(chip);
  }

  removeChip(event) {
    event.target.closest(`[data-target="${this.identifier}.chip"]`).remove();
  }

  hasReachedMaximum() {
    return this.chipTargets.length >= this.max;
  }

  showList(contents = null) {
    if (this.hasReachedMaximum()) {
      super.showList(`
        <li class="search-select__item search-select__item--error">
          Maximum reached.
        </li>
      `);
      return;
    }

    super.showList(contents);
  }

  search() {
    if (this.hasReachedMaximum()) {
      this.showList();
      return;
    }

    super.search();
  }
}
