import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container', 'item']

  get totalItems() {
    return this.itemTargets.length;
  }

  get itemClassName() {
    return 'carousel__item';
  }

  get initialSlideNumber() {
    var slideNumber = 0;

    this.itemTargets.forEach((item, index) => {
      if (item.classList.contains('initial')) { slideNumber = index; }
    })

    return slideNumber;
  }

  get activeSlideNumber() {
    var slideNumber = 0;

    this.itemTargets.forEach((item, index) => {
      if (item.classList.contains('active')) { slideNumber = index; }
    })

    return slideNumber;
  }

  get prevSlideNumber() {
    var slideNumber = this.totalItems - 1;

    this.itemTargets.forEach((item, index) => {
      if (item.classList.contains('prev')) { slideNumber = index; }
    })

    return slideNumber;
  }

  get nextSlideNumber() {
    var slideNumber = 1;

    this.itemTargets.forEach((item, index) => {
      if (item.classList.contains('next')) { slideNumber = index; }
    })

    return slideNumber;
  }

  get initialSlide() {
    return this.itemTargets[this.initialSlideNumber];
  }

  get activeSlide() {
    return this.itemTargets[this.activeSlideNumber];
  }

  get prevSlide() {
    return this.itemTargets[this.prevSlideNumber];
  }

  get nextSlide() {
    return this.itemTargets[this.nextSlideNumber];
  }

  set activeSlide(slideNumber) {
    this.itemTargets[slideNumber].className = this.itemClassName + ' active';
  }

  set prevSlide(slideNumber) {
    if (this.itemTargets[slideNumber] === undefined) {}

    this.itemTargets[slideNumber].className = this.itemClassName + ' prev';
  }

  set nextSlide(slideNumber) {
    if (this.itemTargets[slideNumber] === undefined) {}

    this.itemTargets[slideNumber].className = this.itemClassName + ' next';
  }

  connect() {
    this.initialSlide.classList.add('active');

    if (this.prevAvailableSlideNumber(this.initialSlideNumber) != this.nextAvailableSlideNumber(this.initialSlideNumber)) {
      this.prevSlide = this.prevAvailableSlideNumber(this.initialSlideNumber);
      this.nextSlide = this.nextAvailableSlideNumber(this.initialSlideNumber);
    }
  }

  prev(event) {
    var prevslide;
    if (this.prevAvailableSlideNumber(this.initialSlideNumber) == this.nextAvailableSlideNumber(this.initialSlideNumber)){
      prevslide = this.nextAvailableSlideNumber(this.activeSlideNumber);
    } else {
      prevslide = this.prevAvailableSlideNumber(this.activeSlideNumber);
    }

    this.moveCarouselTo(prevslide);
  }

  next() {
    var nextSlide;
    if (this.prevAvailableSlideNumber(this.initialSlideNumber) == this.nextAvailableSlideNumber(this.initialSlideNumber)){
      nextSlide = this.prevAvailableSlideNumber(this.activeSlideNumber);
    } else{
      nextSlide = this.nextAvailableSlideNumber(this.activeSlideNumber);
    }

    this.moveCarouselTo(nextSlide);
  }

  moveCarouselTo(slide) {
    this.resetSlides();
    this.activeSlide = slide;
    this.nextSlide = this.nextAvailableSlideNumber(slide);
    this.prevSlide = this.prevAvailableSlideNumber(slide);
  }

  resetSlides() {
    this.activeSlide.className = this.itemClassName
    this.prevSlide.className = this.itemClassName
    this.nextSlide.className = this.itemClassName
  }

  nextAvailableSlideNumber(slideNumber) {
    if (slideNumber === (this.totalItems - 1)) {
      slideNumber = 0;
    } else {
      slideNumber++;
    }

    return slideNumber;
  }

  prevAvailableSlideNumber(slideNumber) {
    if (slideNumber === 0) {
      slideNumber = (this.totalItems - 1);
    } else {
      slideNumber--;
    }

    return slideNumber;
  }
}
