import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const first = document.getElementById('cb1')
    const second = document.getElementById('cb2')
    const selected = document.getElementById('selected-field')

    if(first.checked == true) {
      selected.value = 'first_answer'
      this.setBackground(document.getElementById('1'))
    }
    if(second.checked == true) {
      selected.value = 'second_answer'
      this.setBackground(document.getElementById('2'))
    }
  }

  selectAnswer(event) {
    const hiddenCheckboxes = document.getElementsByClassName('bmc-hidden-checkbox')
    const targets = document.getElementsByClassName('bmc-answers__boolean__answers__col')

    this.clearCheckboxes(hiddenCheckboxes)
    this.clearBackgrounds(targets)

    const elem = document.getElementById('cb' + event.target.id)

    this.setBackground(document.getElementById(event.target.id))
    elem.checked = true

    const selected = document.getElementById('selected-field')
    if (event.target.id == '1') {
      selected.value = 'first_answer'
    }
    else if (event.target.id == '2') {
      selected.value = 'second_answer'
    }
  }

  clearCheckboxes(checkboxes) {
    for(var i = 0; i < checkboxes.length; i++){
      checkboxes[i].checked = false;
    }
  }

  clearBackgrounds(targets) {
    for(var i = 0; i < targets.length; i++){
      targets[i].classList.remove('bmc-answers__boolean__answers__highlight')
    }
  }

  setBackground(element) {
    element.classList.add('bmc-answers__boolean__answers__highlight')
  }
}
